import React from 'react';
import { MonthlyTrends } from '../components/budget/MonthlyTrends';
import { ExpenseBreakdown } from '../components/budget/ExpenseBreakdown';
import { BudgetSummaryPanel } from '../components/budget/BudgetSummaryPanel';
import { SeasonBudget } from '../components/budget/SeasonBudget';
import { EventBudgets } from '../components/budget/EventBudgets';
import { MOCK_EVENTS } from '../data/mockData';
import type { Budget, ExpenseCategory } from '../types/budget';

const EXPENSE_COLORS: Record<ExpenseCategory, string> = {
  'Entry Fees': '#EF4444',
  'Travel': '#F59E0B',
  'Fuel': '#10B981',
  'Tires': '#3B82F6',
  'Parts': '#6366F1',
  'Maintenance': '#8B5CF6',
  'Equipment': '#EC4899',
  'Safety Gear': '#F43F5E',
  'Marketing': '#14B8A6',
  'Insurance': '#06B6D4',
  'Other': '#6B7280'
};

export default function Budget() {
  const [budget, setBudget] = React.useState<Budget>({
    id: '1',
    seasonYear: new Date().getFullYear(),
    seasonExpenses: [],
    seasonIncome: [],
    events: MOCK_EVENTS,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  });

  // Calculate totals for the summary panel
  const calculateTotals = () => {
    const seasonExpensesTotal = budget.seasonExpenses.reduce(
      (sum, expense) => sum + (expense.amount * expense.quantity),
      0
    );

    const seasonIncomeTotal = budget.seasonIncome.reduce(
      (sum, income) => sum + (income.amount * income.quantity),
      0
    );

    const eventExpensesTotal = budget.events.reduce(
      (sum, event) => sum + event.budget.expenses.reduce(
        (eventSum, expense) => eventSum + (expense.amount * expense.quantity),
        0
      ),
      0
    );

    const totalExpenses = seasonExpensesTotal + eventExpensesTotal;
    const totalIncome = seasonIncomeTotal;
    const netBalance = totalIncome - totalExpenses;

    return { totalExpenses, totalIncome, netBalance };
  };

  // Calculate expense breakdown data for pie chart
  const calculateExpenseBreakdown = () => {
    const breakdown: Record<ExpenseCategory, number> = {
      'Entry Fees': 0,
      'Travel': 0,
      'Fuel': 0,
      'Tires': 0,
      'Parts': 0,
      'Maintenance': 0,
      'Equipment': 0,
      'Safety Gear': 0,
      'Marketing': 0,
      'Insurance': 0,
      'Other': 0
    };

    // Add season expenses
    budget.seasonExpenses.forEach(expense => {
      breakdown[expense.category] += expense.amount * expense.quantity;
    });

    // Add event expenses
    budget.events.forEach(event => {
      event.budget.expenses.forEach(expense => {
        breakdown[expense.category] += expense.amount * expense.quantity;
      });
    });

    return Object.entries(breakdown)
      .filter(([_, value]) => value > 0)
      .map(([name, value]) => ({ name, value }));
  };

  // Calculate monthly trends data
  const calculateMonthlyTrends = () => {
    return Array.from({ length: 12 }, (_, i) => {
      const month = new Date(budget.seasonYear, i).toLocaleString('default', { month: 'short' });
      
      // Get events for this month
      const monthEvents = budget.events.filter(
        event => new Date(event.date).getMonth() === i
      );

      // Calculate expenses for events this month
      const eventExpenses = monthEvents.reduce(
        (sum, event) => sum + event.budget.expenses.reduce(
          (eventSum, expense) => eventSum + (expense.amount * expense.quantity),
          0
        ),
        0
      );

      // Add monthly portion of season expenses
      const monthlySeasonExpenses = budget.seasonExpenses
        .filter(expense => expense.frequency === 'monthly' || 
               (expense.frequency === 'yearly' && i === 0) ||
               (expense.frequency === 'quarterly' && i % 3 === 0))
        .reduce((sum, expense) => {
          const amount = expense.amount * expense.quantity;
          if (expense.frequency === 'yearly') return sum + (amount / 12);
          if (expense.frequency === 'quarterly') return sum + (amount / 3);
          return sum + amount;
        }, 0);

      const monthlySeasonIncome = budget.seasonIncome
        .filter(income => income.frequency === 'monthly' ||
               (income.frequency === 'yearly' && i === 0) ||
               (income.frequency === 'quarterly' && i % 3 === 0))
        .reduce((sum, income) => {
          const amount = income.amount * income.quantity;
          if (income.frequency === 'yearly') return sum + (amount / 12);
          if (income.frequency === 'quarterly') return sum + (amount / 3);
          return sum + amount;
        }, 0);

      return {
        month,
        expenses: eventExpenses + monthlySeasonExpenses,
        income: monthlySeasonIncome
      };
    });
  };

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-white">Budget</h1>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <MonthlyTrends data={calculateMonthlyTrends()} />
        <ExpenseBreakdown 
          data={calculateExpenseBreakdown()} 
          colors={EXPENSE_COLORS} 
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2 space-y-6">
          <div className="bg-dark-200 rounded-lg">
            <div className="border-b border-dark-50 p-4">
              <h2 className="text-lg font-semibold text-white">Season Budget</h2>
            </div>
            <SeasonBudget 
              budget={budget} 
              onUpdate={setBudget} 
              type="expenses" 
            />
          </div>

          <div className="bg-dark-200 rounded-lg">
            <div className="border-b border-dark-50 p-4">
              <h2 className="text-lg font-semibold text-white">Event Budgets</h2>
            </div>
            <EventBudgets 
              budget={budget} 
              onUpdate={setBudget} 
            />
          </div>
        </div>

        <div className="lg:sticky lg:top-6 h-fit">
          <BudgetSummaryPanel totals={calculateTotals()} />
        </div>
      </div>
    </div>
  );
}