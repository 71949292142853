import React, { useState } from 'react';
import { Plus, Edit, Trash2 } from 'lucide-react';
import type { EventBudget, BudgetItem } from '../../types/event';
import type { ExpenseCategory } from '../../types/budget';

interface EventExpensesProps {
  budget: EventBudget;
  isEditing: boolean;
  onChange: (budget: EventBudget) => void;
}

const expenseCategories: ExpenseCategory[] = [
  'Entry Fees',
  'Travel',
  'Fuel',
  'Tires',
  'Parts',
  'Maintenance',
  'Equipment',
  'Safety Gear',
  'Marketing',
  'Insurance',
  'Other'
];

export default function EventExpenses({ budget, isEditing, onChange }: EventExpensesProps) {
  const [newExpense, setNewExpense] = useState<Partial<BudgetItem>>({
    description: '',
    amount: 0,
    category: 'Other',
    quantity: 1
  });

  const handleAddExpense = () => {
    if (!newExpense.description || !newExpense.amount) return;

    const expense: BudgetItem = {
      id: Math.random().toString(36).substr(2, 9),
      description: newExpense.description,
      amount: Number(newExpense.amount),
      category: newExpense.category as ExpenseCategory,
      quantity: Number(newExpense.quantity) || 1,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };

    onChange({
      ...budget,
      expenses: [...budget.expenses, expense]
    });

    setNewExpense({
      description: '',
      amount: 0,
      category: 'Other',
      quantity: 1
    });
  };

  const handleUpdateExpense = (id: string, updates: Partial<BudgetItem>) => {
    onChange({
      ...budget,
      expenses: budget.expenses.map(expense =>
        expense.id === id
          ? { ...expense, ...updates, updatedAt: new Date().toISOString() }
          : expense
      )
    });
  };

  const handleDeleteExpense = (id: string) => {
    if (!window.confirm('Are you sure you want to delete this expense?')) return;

    onChange({
      ...budget,
      expenses: budget.expenses.filter(expense => expense.id !== id)
    });
  };

  const totalExpenses = budget.expenses.reduce(
    (sum, expense) => sum + expense.amount * expense.quantity,
    0
  );

  return (
    <div className="bg-dark-200 rounded-lg p-6">
      <h2 className="text-lg font-semibold text-white mb-6">Event Expenses</h2>

      {isEditing && (
        <div className="mb-6 bg-dark-100 rounded-lg p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Description
              </label>
              <input
                type="text"
                value={newExpense.description}
                onChange={(e) => setNewExpense(prev => ({ ...prev, description: e.target.value }))}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Category
              </label>
              <select
                value={newExpense.category}
                onChange={(e) => setNewExpense(prev => ({ ...prev, category: e.target.value as ExpenseCategory }))}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
              >
                {expenseCategories.map(category => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Amount ($)
              </label>
              <input
                type="number"
                value={newExpense.amount}
                onChange={(e) => setNewExpense(prev => ({ ...prev, amount: Number(e.target.value) }))}
                min="0"
                step="0.01"
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Quantity
              </label>
              <input
                type="number"
                value={newExpense.quantity}
                onChange={(e) => setNewExpense(prev => ({ ...prev, quantity: Number(e.target.value) }))}
                min="1"
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
              />
            </div>
          </div>
          <div className="flex justify-end">
            <button
              onClick={handleAddExpense}
              disabled={!newExpense.description || !newExpense.amount}
              className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600 disabled:opacity-50"
            >
              <Plus className="w-4 h-4" />
              Add Expense
            </button>
          </div>
        </div>
      )}

      <div className="space-y-4">
        {budget.expenses.length === 0 ? (
          <p className="text-center text-gray-400 py-4">No expenses added yet.</p>
        ) : (
          budget.expenses.map((expense) => (
            <div
              key={expense.id}
              className="flex items-center justify-between p-4 bg-dark-100 rounded-lg"
            >
              <div>
                <p className="text-white font-medium">{expense.description}</p>
                <p className="text-sm text-gray-400">{expense.category}</p>
              </div>
              <div className="flex items-center gap-4">
                <p className="text-white">
                  {expense.quantity > 1 ? `${expense.quantity}x ` : ''}
                  ${expense.amount.toLocaleString()}
                </p>
                {isEditing && (
                  <div className="flex gap-2">
                    <button
                      onClick={() => handleDeleteExpense(expense.id)}
                      className="p-1 text-gray-400 hover:text-red-500"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))
        )}
      </div>

      <div className="mt-6 pt-6 border-t border-dark-50 flex justify-between items-center">
        <p className="text-gray-400">Total Expenses</p>
        <p className="text-xl font-semibold text-white">
          ${totalExpenses.toLocaleString()}
        </p>
      </div>
    </div>
  );
}