import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Calendar, CreditCard, AlertTriangle } from 'lucide-react';
import { format } from 'date-fns';

export default function Subscription() {
  const { user } = useAuth();

  if (!user?.subscription) {
    return (
      <div className="space-y-6">
        <h1 className="text-2xl font-bold text-white">Subscription</h1>
        
        <div className="bg-dark-200 rounded-lg p-6">
          <div className="text-center">
            <AlertTriangle className="w-12 h-12 text-yellow-500 mx-auto mb-4" />
            <h2 className="text-lg font-semibold text-white mb-2">
              No Active Subscription
            </h2>
            <p className="text-gray-400 mb-6">
              You currently don't have an active subscription.
            </p>
            <button
              onClick={() => {/* Handle subscription */}}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-600"
            >
              Subscribe Now
            </button>
          </div>
        </div>
      </div>
    );
  }

  const handleCancelSubscription = async () => {
    if (window.confirm('Are you sure you want to cancel your subscription?')) {
      // Handle subscription cancellation
      console.log('Subscription cancelled');
    }
  };

  const handleUpdatePayment = () => {
    // Handle payment method update
    console.log('Update payment method');
  };

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-white">Subscription</h1>

      {/* Current Plan */}
      <div className="bg-dark-200 rounded-lg p-6">
        <h2 className="text-lg font-semibold text-white mb-6">Current Plan</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <p className="text-sm text-gray-400">Plan</p>
            <p className="text-lg font-medium text-white capitalize">
              {user.subscription.plan}
            </p>
          </div>
          <div>
            <p className="text-sm text-gray-400">Status</p>
            <p className="text-lg font-medium text-white capitalize">
              {user.subscription.status}
            </p>
          </div>
          <div>
            <p className="text-sm text-gray-400">Next Payment</p>
            <p className="text-lg font-medium text-white">
              {format(new Date(user.subscription.renewalDate), 'MMM d, yyyy')}
            </p>
          </div>
        </div>
      </div>

      {/* Payment Method */}
      <div className="bg-dark-200 rounded-lg p-6">
        <div className="flex justify-between items-start mb-6">
          <div>
            <h2 className="text-lg font-semibold text-white">Payment Method</h2>
            <p className="text-sm text-gray-400">
              Update your billing information and payment method.
            </p>
          </div>
          <button
            onClick={handleUpdatePayment}
            className="px-4 py-2 bg-dark-100 text-white rounded-md hover:bg-dark-50"
          >
            Update
          </button>
        </div>
        <div className="flex items-center gap-4">
          <div className="p-3 bg-dark-100 rounded-md">
            <CreditCard className="w-6 h-6 text-primary-500" />
          </div>
          <div>
            <p className="text-white">•••• •••• •••• 4242</p>
            <p className="text-sm text-gray-400">Expires 12/25</p>
          </div>
        </div>
      </div>

      {/* Billing History */}
      <div className="bg-dark-200 rounded-lg p-6">
        <h2 className="text-lg font-semibold text-white mb-6">Billing History</h2>
        <div className="space-y-4">
          {[...Array(3)].map((_, i) => (
            <div
              key={i}
              className="flex items-center justify-between py-3 border-b border-dark-50 last:border-0"
            >
              <div className="flex items-center gap-4">
                <Calendar className="w-5 h-5 text-gray-400" />
                <div>
                  <p className="text-white">Monthly Subscription</p>
                  <p className="text-sm text-gray-400">
                    {format(
                      new Date(2024, 2 - i, 15),
                      'MMM d, yyyy'
                    )}
                  </p>
                </div>
              </div>
              <div className="text-right">
                <p className="text-white">$29.00</p>
                <button className="text-sm text-primary-500 hover:text-primary-400">
                  View Receipt
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Cancel Subscription */}
      <div className="bg-dark-200 rounded-lg p-6">
        <h2 className="text-lg font-semibold text-white mb-2">
          Cancel Subscription
        </h2>
        <p className="text-gray-400 mb-4">
          Your subscription will remain active until the end of your current billing period.
        </p>
        <button
          onClick={handleCancelSubscription}
          className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
        >
          Cancel Subscription
        </button>
      </div>
    </div>
  );
}