import React, { useState } from 'react';
import { Edit, Save, X, Lightbulb } from 'lucide-react';
import type { VehicleSetup } from '../../../types/vehicleSetup';

interface VehicleSetupProps {
  setup: VehicleSetup;
  onUpdateSetup: (setup: VehicleSetup) => void;
  weightUnit: 'lbs' | 'kg';
}

export default function VehicleSetupComponent({ setup, onUpdateSetup, weightUnit }: VehicleSetupProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedSetup, setEditedSetup] = useState(setup);
  const [showInsights, setShowInsights] = useState(false);

  const handleChange = (section: keyof VehicleSetup, field: string, value: number) => {
    setEditedSetup(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value
      },
      updatedAt: new Date().toISOString()
    }));
  };

  const handleSave = () => {
    onUpdateSetup(editedSetup);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedSetup(setup);
    setIsEditing(false);
  };

  return (
    <div className="bg-dark-200 rounded-lg p-6">
      <div className="flex justify-between items-start mb-6">
        <div>
          <h2 className="text-xl font-semibold text-white">Vehicle Setup</h2>
          <p className="text-sm text-gray-400">
            Last updated: {new Date(setup.updatedAt).toLocaleDateString()}
          </p>
        </div>
        <div className="flex gap-2">
          <button
            onClick={() => setShowInsights(!showInsights)}
            className="p-2 text-gray-400 hover:text-primary-500 rounded-lg hover:bg-dark-50 relative group"
          >
            <Lightbulb className={`w-5 h-5 ${showInsights ? 'text-primary-500' : ''}`} />
            <span className="absolute hidden group-hover:block right-0 top-full mt-2 w-48 p-2 bg-dark-100 text-xs text-gray-400 rounded-md">
              View AI-powered setup insights
            </span>
          </button>
          {isEditing ? (
            <>
              <button
                onClick={handleSave}
                className="p-2 text-primary-500 hover:text-primary-400 rounded-lg hover:bg-dark-50"
              >
                <Save className="w-5 h-5" />
              </button>
              <button
                onClick={handleCancel}
                className="p-2 text-gray-400 hover:text-gray-300 rounded-lg hover:bg-dark-50"
              >
                <X className="w-5 h-5" />
              </button>
            </>
          ) : (
            <button
              onClick={() => setIsEditing(true)}
              className="p-2 text-gray-400 hover:text-primary-500 rounded-lg hover:bg-dark-50"
            >
              <Edit className="w-5 h-5" />
            </button>
          )}
        </div>
      </div>

      {showInsights && (
        <div className="mb-6 bg-dark-100 rounded-lg p-4 border border-primary-500/20">
          <div className="flex items-center gap-2 mb-4">
            <Lightbulb className="w-5 h-5 text-primary-500" />
            <h3 className="text-lg font-medium text-white">Setup Insights</h3>
          </div>
          <div className="space-y-4">
            <div className="flex items-start gap-3">
              <div className="w-2 h-2 rounded-full bg-primary-500 mt-2" />
              <div>
                <p className="text-gray-300">Based on your current alignment settings, consider increasing front camber by 0.5° to improve turn-in response.</p>
                <button className="mt-1 text-sm text-primary-500 opacity-50 cursor-not-allowed">
                  Apply suggestion
                </button>
              </div>
            </div>
            <div className="flex items-start gap-3">
              <div className="w-2 h-2 rounded-full bg-primary-500 mt-2" />
              <div>
                <p className="text-gray-300">Your current brake bias might be causing understeer. A 2% rearward shift could improve balance.</p>
                <button className="mt-1 text-sm text-primary-500 opacity-50 cursor-not-allowed">
                  Apply suggestion
                </button>
              </div>
            </div>
            <div className="flex items-start gap-3">
              <div className="w-2 h-2 rounded-full bg-primary-500 mt-2" />
              <div>
                <p className="text-gray-300">Corner weights show a 2% cross-weight. Consider adjusting ride heights to achieve better balance.</p>
                <button className="mt-1 text-sm text-primary-500 opacity-50 cursor-not-allowed">
                  Apply suggestion
                </button>
              </div>
            </div>
            <div className="mt-4 pt-4 border-t border-dark-50">
              <p className="text-xs text-gray-500">
                AI suggestions are currently in preview. Setup recommendations will be based on vehicle data, track conditions, and historical performance when available.
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Alignment Section */}
        <div>
          <h3 className="text-lg font-medium text-white mb-4">Alignment</h3>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Front Camber (°)
              </label>
              <input
                type="number"
                value={editedSetup.alignment.frontCamber}
                onChange={(e) => handleChange('alignment', 'frontCamber', Number(e.target.value))}
                disabled={!isEditing}
                step="0.1"
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Rear Camber (°)
              </label>
              <input
                type="number"
                value={editedSetup.alignment.rearCamber}
                onChange={(e) => handleChange('alignment', 'rearCamber', Number(e.target.value))}
                disabled={!isEditing}
                step="0.1"
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Front Toe (mm)
              </label>
              <input
                type="number"
                value={editedSetup.alignment.frontToe}
                onChange={(e) => handleChange('alignment', 'frontToe', Number(e.target.value))}
                disabled={!isEditing}
                step="0.1"
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Rear Toe (mm)
              </label>
              <input
                type="number"
                value={editedSetup.alignment.rearToe}
                onChange={(e) => handleChange('alignment', 'rearToe', Number(e.target.value))}
                disabled={!isEditing}
                step="0.1"
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Caster (°)
              </label>
              <input
                type="number"
                value={editedSetup.alignment.caster}
                onChange={(e) => handleChange('alignment', 'caster', Number(e.target.value))}
                disabled={!isEditing}
                step="0.1"
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
            </div>
          </div>
        </div>

        {/* Suspension Section */}
        <div>
          <h3 className="text-lg font-medium text-white mb-4">Suspension</h3>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Front Ride Height (mm)
              </label>
              <input
                type="number"
                value={editedSetup.suspension.frontRideHeight}
                onChange={(e) => handleChange('suspension', 'frontRideHeight', Number(e.target.value))}
                disabled={!isEditing}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Rear Ride Height (mm)
              </label>
              <input
                type="number"
                value={editedSetup.suspension.rearRideHeight}
                onChange={(e) => handleChange('suspension', 'rearRideHeight', Number(e.target.value))}
                disabled={!isEditing}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Front Spring Rate (N/mm)
              </label>
              <input
                type="number"
                value={editedSetup.suspension.frontSpringRate}
                onChange={(e) => handleChange('suspension', 'frontSpringRate', Number(e.target.value))}
                disabled={!isEditing}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Rear Spring Rate (N/mm)
              </label>
              <input
                type="number"
                value={editedSetup.suspension.rearSpringRate}
                onChange={(e) => handleChange('suspension', 'rearSpringRate', Number(e.target.value))}
                disabled={!isEditing}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Front Rebound
              </label>
              <input
                type="number"
                value={editedSetup.suspension.frontRebound}
                onChange={(e) => handleChange('suspension', 'frontRebound', Number(e.target.value))}
                disabled={!isEditing}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Rear Rebound
              </label>
              <input
                type="number"
                value={editedSetup.suspension.rearRebound}
                onChange={(e) => handleChange('suspension', 'rearRebound', Number(e.target.value))}
                disabled={!isEditing}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Front Compression
              </label>
              <input
                type="number"
                value={editedSetup.suspension.frontCompression}
                onChange={(e) => handleChange('suspension', 'frontCompression', Number(e.target.value))}
                disabled={!isEditing}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Rear Compression
              </label>
              <input
                type="number"
                value={editedSetup.suspension.rearCompression}
                onChange={(e) => handleChange('suspension', 'rearCompression', Number(e.target.value))}
                disabled={!isEditing}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
            </div>
          </div>
        </div>

        {/* Brakes Section */}
        <div>
          <h3 className="text-lg font-medium text-white mb-4">Brakes</h3>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Brake Bias (%)
              </label>
              <input
                type="number"
                value={editedSetup.brakes.brakeBias}
                onChange={(e) => handleChange('brakes', 'brakeBias', Number(e.target.value))}
                disabled={!isEditing}
                min="0"
                max="100"
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Front Pressure (psi)
              </label>
              <input
                type="number"
                value={editedSetup.brakes.frontPressure}
                onChange={(e) => handleChange('brakes', 'frontPressure', Number(e.target.value))}
                disabled={!isEditing}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Rear Pressure (psi)
              </label>
              <input
                type="number"
                value={editedSetup.brakes.rearPressure}
                onChange={(e) => handleChange('brakes', 'rearPressure', Number(e.target.value))}
                disabled={!isEditing}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
            </div>
          </div>
        </div>

        {/* Tire Pressures Section */}
        <div>
          <h3 className="text-lg font-medium text-white mb-4">Tire Pressures</h3>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Front Cold (psi)
              </label>
              <input
                type="number"
                value={editedSetup.tires.frontPressureCold}
                onChange={(e) => handleChange('tires', 'frontPressureCold', Number(e.target.value))}
                disabled={!isEditing}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Rear Cold (psi)
              </label>
              <input
                type="number"
                value={editedSetup.tires.rearPressureCold}
                onChange={(e) => handleChange('tires', 'rearPressureCold', Number(e.target.value))}
                disabled={!isEditing}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Front Hot (psi)
              </label>
              <input
                type="number"
                value={editedSetup.tires.frontPressureHot}
                onChange={(e) => handleChange('tires', 'frontPressureHot', Number(e.target.value))}
                disabled={!isEditing}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Rear Hot (psi)
              </label>
              <input
                type="number"
                value={editedSetup.tires.rearPressureHot}
                onChange={(e) => handleChange('tires', 'rearPressureHot', Number(e.target.value))}
                disabled={!isEditing}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
            </div>
          </div>
        </div>

        {/* Corner Weights Section */}
        <div>
          <h3 className="text-lg font-medium text-white mb-4">Corner Weights</h3>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Front Left ({weightUnit})
              </label>
              <input
                type="number"
                value={editedSetup.weight.cornerWeights.frontLeft}
                onChange={(e) => {
                  const newSetup = { ...editedSetup };
                  newSetup.weight.cornerWeights.frontLeft = Number(e.target.value);
                  setEditedSetup(newSetup);
                }}
                disabled={!isEditing}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Front Right ({weightUnit})
              </label>
              <input
                type="number"
                value={editedSetup.weight.cornerWeights.frontRight}
                onChange={(e) => {
                  const newSetup = { ...editedSetup };
                  newSetup.weight.cornerWeights.frontRight = Number(e.target.value);
                  setEditedSetup(newSetup);
                }}
                disabled={!isEditing}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Rear Left ({weightUnit})
              </label>
              <input
                type="number"
                value={editedSetup.weight.cornerWeights.rearLeft}
                onChange={(e) => {
                  const newSetup = { ...editedSetup };
                  newSetup.weight.cornerWeights.rearLeft = Number(e.target.value);
                  setEditedSetup(newSetup);
                }}
                disabled={!isEditing}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Rear Right ({weightUnit})
              </label>
              <input
                type="number"
                value={editedSetup.weight.cornerWeights.rearRight}
                onChange={(e) => {
                  const newSetup = { ...editedSetup };
                  newSetup.weight.cornerWeights.rearRight = Number(e.target.value);
                  setEditedSetup(newSetup);
                }}
                disabled={!isEditing}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
            </div>
          </div>
        </div>

        {/* Ballast Section */}
        <div>
          <h3 className="text-lg font-medium text-white mb-4">Ballast</h3>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Weight ({weightUnit})
              </label>
              <input
                type="number"
                value={editedSetup.weight.ballast}
                onChange={(e) => {
                  const newSetup = { ...editedSetup };
                  newSetup.weight.ballast = Number(e.target.value);
                  setEditedSetup(newSetup);
                }}
                disabled={!isEditing}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Position
              </label>
              <input
                type="text"
                value={editedSetup.weight.ballastPosition}
                onChange={(e) => {
                  const newSetup = { ...editedSetup };
                  newSetup.weight.ballastPosition = e.target.value;
                  setEditedSetup(newSetup);
                }}
                disabled={!isEditing}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}