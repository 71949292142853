import React, { useEffect } from 'react';
import { AuthProvider } from './contexts/AuthContext';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import ProtectedRoute from './components/auth/ProtectedRoute';
import { getAuth } from 'firebase/auth';

// Pages
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/auth/LoginPage';
import SignupPage from './pages/auth/SignupPage';
import PaymentPage from './pages/auth/PaymentPage';
import Dashboard from './pages/Dashboard';
import Garage from './pages/Garage';
import VehicleDetail from './pages/VehicleDetail';
import Schedule from './pages/Schedule';
import EventDetail from './pages/EventDetail';
import Budget from './pages/Budget';
import Tasks from './pages/Tasks';
import TaskDetail from './pages/TaskDetail';
import Sponsors from './pages/Sponsors';
import Checklists from './pages/Checklists';
import ChecklistDetail from './pages/ChecklistDetail';
import Maintenance from './pages/Maintenance';
import Account from './pages/Account';
import Subscription from './pages/Subscription';
import Settings from './pages/Settings';

// Types
import type { Vehicle } from './types/vehicle';
import type { Task } from './types/task';
import type { Event } from './types/event';
import type { Checklist } from './types/checklist';

const auth = getAuth();

export default function App() {
  useEffect(() => {
    console.log('App mounted');
    
    // Test if Firebase is initialized
    if (auth) {
      console.log('Firebase Auth is initialized');
    } else {
      console.error('Firebase Auth is not initialized');
    }
  }, []);

  const [vehicles, setVehicles] = React.useState<Vehicle[]>([]);
  const [checklists, setChecklists] = React.useState<Checklist[]>([]);
  const [events, setEvents] = React.useState<Event[]>([]);
  const [tasks, setTasks] = React.useState<Task[]>([]);

  const handleUpdateVehicle = (updatedVehicle: Vehicle) => {
    setVehicles(prev => 
      prev.map(vehicle => 
        vehicle.id === updatedVehicle.id ? updatedVehicle : vehicle
      )
    );
  };

  const handleUpdateChecklist = (updatedChecklist: Checklist) => {
    setChecklists(prev =>
      prev.map(checklist =>
        checklist.id === updatedChecklist.id ? updatedChecklist : checklist
      )
    );
  };

  const handleUpdateEvent = (updatedEvent: Event) => {
    setEvents(prev =>
      prev.map(event =>
        event.id === updatedEvent.id ? updatedEvent : event
      )
    );
  };

  const handleUpdateTask = (updatedTask: Task) => {
    setTasks(prev =>
      prev.map(task =>
        task.id === updatedTask.id ? updatedTask : task
      )
    );
  };

  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public routes */}
          <Route path="/landing" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/payment" element={<PaymentPage />} />

          {/* Protected routes */}
          <Route element={<ProtectedRoute><Layout /></ProtectedRoute>}>
            <Route path="/" element={<Dashboard />} />
            <Route 
              path="/garage" 
              element={<Garage vehicles={vehicles} setVehicles={setVehicles} />} 
            />
            <Route 
              path="/garage/vehicles/:id" 
              element={<VehicleDetail vehicles={vehicles} onUpdateVehicle={handleUpdateVehicle} />} 
            />
            <Route 
              path="/schedule" 
              element={<Schedule events={events} setEvents={setEvents} />} 
            />
            <Route 
              path="/schedule/:id" 
              element={<EventDetail events={events} onUpdateEvent={handleUpdateEvent} />} 
            />
            <Route path="/budget" element={<Budget />} />
            <Route 
              path="/tasks" 
              element={<Tasks tasks={tasks} setTasks={setTasks} />} 
            />
            <Route 
              path="/tasks/:id" 
              element={<TaskDetail tasks={tasks} onUpdateTask={handleUpdateTask} />} 
            />
            <Route path="/sponsors" element={<Sponsors />} />
            <Route 
              path="/checklists" 
              element={<Checklists checklists={checklists} setChecklists={setChecklists} />} 
            />
            <Route 
              path="/checklists/:id" 
              element={<ChecklistDetail checklists={checklists} onUpdateChecklist={handleUpdateChecklist} />} 
            />
            <Route path="/maintenance" element={<Maintenance />} />
            <Route path="/account" element={<Account />} />
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/settings" element={<Settings />} />
          </Route>

          {/* Redirect root to landing for non-authenticated users */}
          <Route 
            path="*" 
            element={<Navigate to="/landing" replace />} 
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}