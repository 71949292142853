import React, { useState } from 'react';
import { X } from 'lucide-react';
import type { Part } from '../../types/part';
import type { Vehicle } from '../../types/vehicle';
import type { ModificationType } from '../../types/vehicle';

interface AddPartModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (part: Omit<Part, 'id' | 'createdAt' | 'updatedAt'>) => void;
  vehicles: Vehicle[];
}

const modificationTypes: ModificationType[] = [
  'Engine',
  'Suspension',
  'Brakes',
  'Transmission',
  'Exterior',
  'Interior',
  'Wheels/Tires',
  'Other'
];

const budgetCategories = [
  'Parts',
  'Tires',
  'Safety Gear',
  'Tools',
  'Other'
] as const;

export default function AddPartModal({ isOpen, onClose, onAdd, vehicles }: AddPartModalProps) {
  const [formData, setFormData] = useState<Omit<Part, 'id' | 'createdAt' | 'updatedAt'>>({
    type: 'Engine',
    name: '',
    description: '',
    manufacturer: '',
    partNumber: '',
    cost: 0,
    quantity: 1,
    location: '',
    condition: 'new',
    compatibleVehicles: [],
    minimumStock: 1,
    budgetCategory: 'Parts'
  });

  if (!isOpen) return null;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onAdd(formData);
    setFormData({
      type: 'Engine',
      name: '',
      description: '',
      manufacturer: '',
      partNumber: '',
      cost: 0,
      quantity: 1,
      location: '',
      condition: 'new',
      compatibleVehicles: [],
      minimumStock: 1,
      budgetCategory: 'Parts'
    });
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
      <div className="bg-dark-200 rounded-lg p-6 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-white">Add Part to Inventory</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-300">
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Basic Info */}
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Part Name
              </label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Type
              </label>
              <select
                value={formData.type}
                onChange={(e) => setFormData(prev => ({ ...prev, type: e.target.value as ModificationType }))}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
              >
                {modificationTypes.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Manufacturer
              </label>
              <input
                type="text"
                value={formData.manufacturer}
                onChange={(e) => setFormData(prev => ({ ...prev, manufacturer: e.target.value }))}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Part Number
              </label>
              <input
                type="text"
                value={formData.partNumber}
                onChange={(e) => setFormData(prev => ({ ...prev, partNumber: e.target.value }))}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
              />
            </div>

            {/* Quantity and Cost */}
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Quantity
              </label>
              <input
                type="number"
                value={formData.quantity}
                onChange={(e) => setFormData(prev => ({ ...prev, quantity: Number(e.target.value) }))}
                min="1"
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Cost per Unit ($)
              </label>
              <input
                type="number"
                value={formData.cost}
                onChange={(e) => setFormData(prev => ({ ...prev, cost: Number(e.target.value) }))}
                min="0"
                step="0.01"
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                required
              />
            </div>

            {/* Storage and Condition */}
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Storage Location
              </label>
              <input
                type="text"
                value={formData.location}
                onChange={(e) => setFormData(prev => ({ ...prev, location: e.target.value }))}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Condition
              </label>
              <select
                value={formData.condition}
                onChange={(e) => setFormData(prev => ({ ...prev, condition: e.target.value as 'new' | 'used' | 'refurbished' }))}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
              >
                <option value="new">New</option>
                <option value="used">Used</option>
                <option value="refurbished">Refurbished</option>
              </select>
            </div>

            {/* Budget Category */}
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Budget Category
              </label>
              <select
                value={formData.budgetCategory}
                onChange={(e) => setFormData(prev => ({ ...prev, budgetCategory: e.target.value as Part['budgetCategory'] }))}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
              >
                {budgetCategories.map(category => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Minimum Stock Level
              </label>
              <input
                type="number"
                value={formData.minimumStock}
                onChange={(e) => setFormData(prev => ({ ...prev, minimumStock: Number(e.target.value) }))}
                min="0"
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
              />
            </div>
          </div>

          {/* Compatible Vehicles */}
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-2">
              Compatible Vehicles
            </label>
            <div className="space-y-2">
              {vehicles.map(vehicle => (
                <label key={vehicle.id} className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={formData.compatibleVehicles?.includes(vehicle.id)}
                    onChange={(e) => {
                      const vehicleId = vehicle.id;
                      setFormData(prev => ({
                        ...prev,
                        compatibleVehicles: e.target.checked
                          ? [...(prev.compatibleVehicles || []), vehicleId]
                          : prev.compatibleVehicles?.filter(id => id !== vehicleId)
                      }));
                    }}
                    className="rounded border-dark-50 bg-dark-300 text-primary-500 focus:ring-primary-500"
                  />
                  <span className="text-white">
                    {vehicle.nickname || `${vehicle.year} ${vehicle.make} ${vehicle.model}`}
                  </span>
                </label>
              ))}
            </div>
          </div>

          {/* Description */}
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              Description
            </label>
            <textarea
              value={formData.description}
              onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
              rows={3}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
            />
          </div>

          {/* Form Actions */}
          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-400 hover:text-gray-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium bg-primary-500 text-white rounded-md hover:bg-primary-600"
            >
              Add to Inventory
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}