import React, { useEffect, useRef, useState } from 'react';
import { MapPin } from 'lucide-react';
import type { Location } from '../../types/event';

interface EventLocationSearchProps {
  location?: Location;
  isEditing: boolean;
  onChange: (location: Location) => void;
}

export default function EventLocationSearch({ 
  location, 
  isEditing, 
  onChange 
}: EventLocationSearchProps) {
  const [searchInput, setSearchInput] = useState(location?.name || '');
  const [predictions, setPredictions] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const autocompleteService = useRef<google.maps.places.AutocompleteService | null>(null);
  const placesService = useRef<google.maps.places.PlacesService | null>(null);

  useEffect(() => {
    // Load Google Places API
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBe3stSrP8_425UrW7UbeedR-0Edsi97GM&libraries=places`;
    script.async = true;
    script.onload = () => {
      autocompleteService.current = new google.maps.places.AutocompleteService();
      // Create a dummy div for PlacesService (required)
      const mapDiv = document.createElement('div');
      const map = new google.maps.Map(mapDiv);
      placesService.current = new google.maps.places.PlacesService(map);
    };
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const handleSearch = (input: string) => {
    setSearchInput(input);

    if (!input || !autocompleteService.current) {
      setPredictions([]);
      return;
    }

    autocompleteService.current.getPlacePredictions(
      {
        input,
        types: ['establishment', 'geocode']
      },
      (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
          setPredictions(predictions);
        } else {
          setPredictions([]);
        }
      }
    );
  };

  const handleSelectPlace = (placeId: string) => {
    if (!placesService.current) return;

    placesService.current.getDetails(
      {
        placeId,
        fields: ['name', 'formatted_address', 'geometry', 'photos']
      },
      (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && place) {
          const newLocation: Location = {
            placeId,
            name: place.name || '',
            address: place.formatted_address,
            latitude: place.geometry?.location?.lat(),
            longitude: place.geometry?.location?.lng(),
            photos: place.photos?.map(photo => photo.getUrl())
          };
          onChange(newLocation);
          setSearchInput(place.name || '');
          setPredictions([]);
        }
      }
    );
  };

  return (
    <div className="bg-dark-200 rounded-lg p-6">
      <h2 className="text-lg font-semibold text-white mb-6">Location</h2>
      
      {isEditing ? (
        <div className="relative">
          <div className="flex items-center gap-2 mb-2">
            <MapPin className="w-5 h-5 text-gray-400" />
            <input
              type="text"
              value={searchInput}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="Search for a location..."
              className="flex-1 bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
            />
          </div>

          {predictions.length > 0 && (
            <div className="absolute z-10 w-full mt-1 bg-dark-100 rounded-md shadow-lg">
              {predictions.map((prediction) => (
                <button
                  key={prediction.place_id}
                  onClick={() => handleSelectPlace(prediction.place_id)}
                  className="w-full px-4 py-2 text-left text-white hover:bg-dark-50 first:rounded-t-md last:rounded-b-md"
                >
                  <p className="font-medium">{prediction.structured_formatting.main_text}</p>
                  <p className="text-sm text-gray-400">
                    {prediction.structured_formatting.secondary_text}
                  </p>
                </button>
              ))}
            </div>
          )}
        </div>
      ) : location ? (
        <div className="flex items-start gap-3">
          <MapPin className="w-5 h-5 text-gray-400 mt-1" />
          <div>
            <p className="text-white font-medium">{location.name}</p>
            {location.address && (
              <p className="text-gray-400 text-sm">{location.address}</p>
            )}
          </div>
        </div>
      ) : (
        <p className="text-gray-400">No location set</p>
      )}
    </div>
  );
}