import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, Lightbulb, ChevronDown, ChevronUp } from 'lucide-react';
import TaskBoard from '../components/tasks/TaskBoard';
import TaskInsights from '../components/tasks/TaskInsights';
import type { Task, TaskStatus } from '../types/task';

// Mock data remains the same...
const initialTasks: Task[] = [
  {
    id: '1',
    title: 'Replace brake pads',
    description: 'Front and rear brake pads need replacement before next track day',
    status: 'ready',
    priority: 7,
    difficulty: 3,
    dueDate: '2024-03-20',
    createdAt: '2024-03-10T10:00:00Z',
    updatedAt: '2024-03-10T10:00:00Z',
    labels: ['maintenance', 'safety']
  },
  {
    id: '2',
    title: 'Align sponsorship decals',
    description: 'Update sponsor decal placement according to new guidelines',
    status: 'on-track',
    priority: 4,
    difficulty: 2,
    dueDate: '2024-03-15',
    createdAt: '2024-03-10T10:00:00Z',
    updatedAt: '2024-03-10T10:00:00Z',
    labels: ['sponsorship', 'appearance']
  }
];

interface TasksProps {
  tasks: Task[];
  setTasks: React.Dispatch<React.SetStateAction<Task[]>>;
}

export default function Tasks({ tasks, setTasks }: TasksProps) {
  const navigate = useNavigate();
  const [showInsights, setShowInsights] = React.useState(false);

  React.useEffect(() => {
    if (tasks.length === 0) {
      setTasks(initialTasks);
    }
  }, [tasks.length, setTasks]);

  const handleStatusChange = (taskId: string, newStatus: TaskStatus) => {
    setTasks(prev => prev.map(task => 
      task.id === taskId 
        ? { ...task, status: newStatus, updatedAt: new Date().toISOString() }
        : task
    ));
  };

  const handleTaskReorder = (items: Task[]) => {
    setTasks(items);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-white">Tasks</h1>
        <div className="flex items-center gap-2">
          <button
            onClick={() => setShowInsights(!showInsights)}
            className="flex items-center gap-2 px-4 py-2 bg-dark-100 text-white rounded-md hover:bg-dark-50 relative group"
          >
            <Lightbulb className={`w-4 h-4 ${showInsights ? 'text-primary-500' : 'text-gray-400'}`} />
            <span className="text-sm">Insights</span>
            {showInsights ? (
              <ChevronUp className="w-4 h-4" />
            ) : (
              <ChevronDown className="w-4 h-4" />
            )}
            <span className="absolute hidden group-hover:block right-0 top-full mt-2 w-48 p-2 bg-dark-100 text-xs text-gray-400 rounded-md z-10">
              View AI-powered task insights and suggestions
            </span>
          </button>
          <button
            onClick={() => navigate('/tasks/new')}
            className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600"
          >
            <Plus className="w-4 h-4" />
            Add Task
          </button>
        </div>
      </div>

      {/* Insights Panel */}
      <div
        className={`bg-dark-200 rounded-lg overflow-hidden transition-all duration-300 ease-in-out ${
          showInsights ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        <div className="p-6">
          <TaskInsights tasks={tasks} />
        </div>
      </div>

      <TaskBoard
        tasks={tasks}
        onStatusChange={handleStatusChange}
        onReorder={handleTaskReorder}
      />
    </div>
  );
}