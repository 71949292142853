import React from 'react';
import { TaskPriority } from '../../types/task';

interface PriorityIndicatorProps {
  priority: TaskPriority;
}

const priorityColors = [
  'bg-green-500',
  'bg-green-400',
  'bg-yellow-500',
  'bg-yellow-400',
  'bg-orange-500',
  'bg-orange-400',
  'bg-red-500',
  'bg-red-400'
];

export default function PriorityIndicator({ priority }: PriorityIndicatorProps) {
  return (
    <div className="flex items-center gap-2">
      <span className="text-xs text-gray-400">Priority</span>
      <div className="flex gap-1">
        {priorityColors.map((color, index) => (
          <div
            key={index}
            className={`w-3 h-3 rounded-full ${
              index < priority ? color : 'bg-dark-300'
            }`}
          />
        ))}
      </div>
    </div>
  );
}