import React, { createContext, useContext, useState, useEffect } from 'react';
import { User } from '../types/auth';
import { 
  GoogleAuthProvider, 
  signInWithPopup, 
  onAuthStateChanged, 
  signOut,
  deleteUser
} from 'firebase/auth';
import { auth, db } from '../config/firebase';
import { doc, setDoc, getDoc, addDoc, collection } from 'firebase/firestore';
import { redirectToCheckout } from '../services/stripeService';

console.log('AuthContext file is being loaded');

interface AuthContextType {
  user: User | null;
  isLoading: boolean;
  isPayingCustomer: boolean;
  loginWithGoogle: () => Promise<void>;
  logout: () => Promise<void>;
  deleteAccount: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  console.log('AuthProvider is rendering');

  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPayingCustomer, setIsPayingCustomer] = useState(false);

  useEffect(() => {
    console.log('AuthProvider mounted');
    let unsubscribe: () => void;

    const handleAuth = async () => {
      try {
        // Set up auth state listener
        unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
          console.log('Auth state changed:', firebaseUser);
          if (firebaseUser) {
            const userDocRef = doc(db, 'users', firebaseUser.uid);
            const userDoc = await getDoc(userDocRef);

            if (!userDoc.exists()) {
              // Create a new user document
              await setDoc(userDocRef, {
                name: firebaseUser.displayName || '',
                email: firebaseUser.email || '',
                role: 'user', // Default role
                programs: [], // Initialize with empty programs
                preferences: {
                  theme: 'light', // Default theme
                  notifications: true // Default notifications setting
                },
                subscription: {
                  status: 'inactive' // Default subscription status
                }
              });

              // Create a default program for the user
              const programDocRef = await addDoc(collection(db, 'programs'), {
                name: `${firebaseUser.displayName || 'User'}'s Program`,
                ownerID: firebaseUser.uid,
                crew: [{ userID: firebaseUser.uid, role: 'owner' }],
                createdAt: new Date().toISOString()
              });

              // Update the user document with the program ID
              await setDoc(userDocRef, {
                programs: [programDocRef.id]
              }, { merge: true });
            }

            // Check subscription status
            const subscriptionStatus = userDoc.data()?.subscription?.status || 'inactive';
            setIsPayingCustomer(subscriptionStatus === 'active');

            // If not a paying customer, redirect to checkout
            // Add paths that should not trigger redirect
            const noRedirectPaths = [
              '/subscription/cancel',
              '/subscription/success',
              '/landing',
              '/login'
            ];

            if (subscriptionStatus !== 'active' && !noRedirectPaths.includes(window.location.pathname)) {
              try {
                console.log('Redirecting to checkout');
                await redirectToCheckout();
              } catch (error) {
                console.error('Error redirecting to checkout:', error);
              }
            }

            setUser({
              id: firebaseUser.uid,
              email: firebaseUser.email || '',
              displayName: firebaseUser.displayName || '',
              status: 'active',
              photoURL: firebaseUser.photoURL || '',
              subscription: userDoc.data()?.subscription
            });
          } else {
            setUser(null);
            setIsPayingCustomer(false);
          }
          setIsLoading(false);
        });
      } catch (error) {
        console.error('Auth error:', error);
        setIsLoading(false);
      }
    };

    handleAuth();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const loginWithGoogle = async () => {
    console.log('Initiating Google sign-in');
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    
    try {
      await signInWithPopup(auth, provider);
    } catch (error: any) {
      console.error('Google sign-in error:', error);
      throw error;
    }
  };

  const logout = async () => {
    setIsLoading(true);
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Logout error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteAccount = async () => {
    if (!auth.currentUser) {
      throw new Error('No user signed in');
    }

    try {
      await deleteUser(auth.currentUser);
    } catch (error) {
      console.error('Error deleting account:', error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider 
      value={{ 
        user, 
        isLoading, 
        isPayingCustomer,
        loginWithGoogle,
        logout,
        deleteAccount
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}