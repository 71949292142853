import React from 'react';
import { Calendar, DollarSign, Car, Wrench, Users, CheckSquare, Lightbulb, ChevronDown, ChevronUp } from 'lucide-react';
import StatCard from '../components/dashboard/StatCard';
import UpcomingEvents from '../components/dashboard/UpcomingEvents';
import VehicleOverview from '../components/dashboard/VehicleOverview';
import BudgetSummary from '../components/dashboard/BudgetSummary';
import TasksSummary from '../components/dashboard/TasksSummary';
import DashboardInsights from '../components/dashboard/DashboardInsights';
import { MOCK_EVENTS } from '../data/mockData';

// Mock data remains unchanged...
const initialVehicles = [
  {
    id: '1',
    make: 'Mazda',
    model: 'MX-5 Miata',
    year: 2019,
    type: 'Convertible',
    weight: 2339,
    weightUnit: 'lbs',
    power: 181,
    powerUnit: 'HP',
    drivetrain: 'RWD',
    primaryUse: 'Autocross',
    nickname: 'Track Day Toy',
    notes: 'Perfect for weekend track days and autocross events.',
    modifications: [
      {
        id: 'mod1',
        type: 'Suspension',
        name: 'Ohlins Coilovers',
        description: 'Road & Track spec',
        cost: 2500,
        date: '2023-08-15'
      }
    ]
  },
  {
    id: '2',
    make: 'Toyota',
    model: 'GR86',
    year: 2022,
    type: 'Coupe',
    weight: 2811,
    weightUnit: 'lbs',
    power: 228,
    powerUnit: 'HP',
    drivetrain: 'RWD',
    primaryUse: 'Time Attack',
    vin: 'JF1ZNAA12N8750142',
    modifications: [
      {
        id: 'mod3',
        type: 'Brakes',
        name: 'Brembo GT Kit',
        description: '4-piston front calipers',
        cost: 3200,
        date: '2023-09-20'
      }
    ]
  }
];

const mockTasks = [
  {
    id: '1',
    title: 'Replace brake pads',
    description: 'Front and rear brake pads need replacement before next track day',
    status: 'ready',
    priority: 7,
    difficulty: 3,
    dueDate: '2024-03-20',
    createdAt: '2024-03-10T10:00:00Z',
    updatedAt: '2024-03-10T10:00:00Z',
    labels: ['maintenance', 'safety']
  },
  {
    id: '2',
    title: 'Align sponsorship decals',
    description: 'Update sponsor decal placement according to new guidelines',
    status: 'on-track',
    priority: 4,
    difficulty: 2,
    dueDate: '2024-03-15',
    createdAt: '2024-03-10T10:00:00Z',
    updatedAt: '2024-03-10T10:00:00Z',
    labels: ['sponsorship', 'appearance']
  },
  {
    id: '3',
    title: 'Schedule dyno session',
    description: 'Book dyno time for power verification after ECU tune',
    status: 'pit-stop',
    priority: 6,
    difficulty: 4,
    dueDate: '2024-03-25',
    createdAt: '2024-03-10T10:00:00Z',
    updatedAt: '2024-03-10T10:00:00Z',
    labels: ['maintenance', 'performance']
  }
];

const mockBudget = {
  id: '1',
  seasonYear: new Date().getFullYear(),
  seasonExpenses: [
    {
      id: 'exp1',
      description: 'Season Entry Fees',
      amount: 2500,
      category: 'Entry Fees',
      quantity: 1,
      frequency: 'yearly',
      createdAt: '2024-01-01T00:00:00Z',
      updatedAt: '2024-01-01T00:00:00Z'
    }
  ],
  seasonIncome: [
    {
      id: 'inc1',
      description: 'Primary Sponsorship',
      amount: 5000,
      category: 'Sponsorship',
      quantity: 1,
      frequency: 'yearly',
      createdAt: '2024-01-01T00:00:00Z',
      updatedAt: '2024-01-01T00:00:00Z'
    }
  ],
  events: MOCK_EVENTS,
  createdAt: '2024-01-01T00:00:00Z',
  updatedAt: '2024-01-01T00:00:00Z'
};

export default function Dashboard() {
  const [showInsights, setShowInsights] = React.useState(false);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-white">Dashboard</h1>
        <button
          onClick={() => setShowInsights(!showInsights)}
          className="flex items-center gap-2 px-4 py-2 bg-dark-100 text-white rounded-md hover:bg-dark-50 relative group"
        >
          <Lightbulb className={`w-4 h-4 ${showInsights ? 'text-primary-500' : 'text-gray-400'}`} />
          <span className="text-sm">Season Insights</span>
          {showInsights ? (
            <ChevronUp className="w-4 h-4" />
          ) : (
            <ChevronDown className="w-4 h-4" />
          )}
          <span className="absolute hidden group-hover:block right-0 top-full mt-2 w-48 p-2 bg-dark-100 text-xs text-gray-400 rounded-md z-10">
            View AI-powered season analysis and recommendations
          </span>
        </button>
      </div>
      
      {/* AI Insights Panel */}
      <div
        className={`bg-dark-200 rounded-lg overflow-hidden transition-all duration-300 ease-in-out ${
          showInsights ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        <div className="p-6">
          <DashboardInsights 
            events={MOCK_EVENTS}
            vehicles={initialVehicles}
            tasks={mockTasks}
            budget={mockBudget}
          />
        </div>
      </div>

      {/* Quick Stats */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-6">
        <StatCard
          title="Active Vehicles"
          value={initialVehicles.length}
          icon={Car}
          description="Vehicles under management"
        />
        <StatCard
          title="Upcoming Events"
          value={MOCK_EVENTS.filter(e => new Date(e.date) > new Date()).length}
          icon={Calendar}
          description="Events in next 30 days"
        />
        <StatCard
          title="Budget Used"
          value="68%"
          icon={DollarSign}
          trend={{ value: 12, isPositive: false }}
          description="Of total season budget"
        />
        <StatCard
          title="Maintenance Due"
          value="2"
          icon={Wrench}
          description="Items requiring attention"
        />
        <StatCard
          title="Active Tasks"
          value={mockTasks.filter(t => t.status !== 'done').length}
          icon={CheckSquare}
          description="Tasks in progress"
        />
        <StatCard
          title="Active Sponsors"
          value="3"
          icon={Users}
          trend={{ value: 50, isPositive: true }}
          description="Current partnerships"
        />
      </div>

      {/* Main Content Grid */}
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
        {/* Left Column */}
        <div className="space-y-6">
          <BudgetSummary budget={mockBudget} />
          <TasksSummary tasks={mockTasks} />
        </div>

        {/* Right Column */}
        <div className="space-y-6">
          <UpcomingEvents events={MOCK_EVENTS} />
          <VehicleOverview vehicles={initialVehicles} />
        </div>
      </div>
    </div>
  );
}