import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Car, Edit, Trash2 } from 'lucide-react';
import type { Vehicle } from '../../types/vehicle';

interface VehicleCardProps {
  vehicle: Vehicle;
  onEdit: (vehicle: Vehicle) => void;
  onDelete: (id: string) => void;
}

export default function VehicleCard({ vehicle, onEdit, onDelete }: VehicleCardProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/garage/vehicles/${vehicle.id}`);
  };

  return (
    <div 
      onClick={handleClick}
      className="bg-dark-200 rounded-lg p-6 hover:bg-dark-100 transition-colors cursor-pointer"
    >
      <div className="flex justify-between items-start mb-4">
        <div className="flex items-center gap-3 flex-1 group">
          <div className="p-2 bg-primary-500/10 rounded-lg group-hover:bg-primary-500/20">
            <Car className="h-6 w-6 text-primary-500" />
          </div>
          <div>
            <h3 className="text-lg font-semibold text-white group-hover:text-primary-500">
              {vehicle.nickname ? (
                <>
                  {vehicle.nickname}
                  <span className="text-sm text-gray-400 ml-2">
                    ({vehicle.year} {vehicle.make} {vehicle.model})
                  </span>
                </>
              ) : (
                `${vehicle.year} ${vehicle.make} ${vehicle.model}`
              )}
            </h3>
            <p className="text-sm text-gray-400">{vehicle.type}</p>
          </div>
        </div>
        <div className="flex gap-2">
          <button
            onClick={(e) => {
              e.stopPropagation();
              onEdit(vehicle);
            }}
            className="p-2 text-gray-400 hover:text-primary-500 rounded-lg hover:bg-dark-50"
          >
            <Edit className="h-4 w-4" />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onDelete(vehicle.id);
            }}
            className="p-2 text-gray-400 hover:text-red-500 rounded-lg hover:bg-dark-50"
          >
            <Trash2 className="h-4 w-4" />
          </button>
        </div>
      </div>
      
      <div className="grid grid-cols-2 gap-4 text-sm">
        <div>
          <p className="text-gray-400">Power Output</p>
          <p className="text-white">{vehicle.power} {vehicle.powerUnit}</p>
        </div>
        <div>
          <p className="text-gray-400">Weight</p>
          <p className="text-white">{vehicle.weight} {vehicle.weightUnit}</p>
        </div>
        <div>
          <p className="text-gray-400">Drivetrain</p>
          <p className="text-white">{vehicle.drivetrain}</p>
        </div>
        <div>
          <p className="text-gray-400">Primary Use</p>
          <p className="text-white">{vehicle.primaryUse}</p>
        </div>
      </div>
      
      {vehicle.vin && (
        <div className="mt-4 pt-4 border-t border-dark-50">
          <p className="text-gray-400 text-sm">VIN</p>
          <p className="text-white text-sm font-mono">{vehicle.vin}</p>
        </div>
      )}
    </div>
  );
}