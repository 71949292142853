import type { Event } from '../types/event';

export const MOCK_EVENTS: Event[] = [
  {
    id: '1',
    title: 'Track Day - Laguna Seca',
    date: new Date(2024, 2, 15),
    type: 'race',
    venue: 'Laguna Seca Raceway',
    description: 'Full day track event',
    budget: {
      expenses: [
        {
          id: 'exp1',
          description: 'Entry Fee',
          amount: 500,
          category: 'Entry Fees',
          quantity: 1,
          createdAt: '2024-03-10T10:00:00Z',
          updatedAt: '2024-03-10T10:00:00Z'
        },
        {
          id: 'exp2',
          description: 'Hotel (2 nights)',
          amount: 200,
          category: 'Travel',
          quantity: 2,
          createdAt: '2024-03-10T10:00:00Z',
          updatedAt: '2024-03-10T10:00:00Z'
        }
      ],
      income: []
    },
    createdAt: '2024-03-10T10:00:00Z',
    updatedAt: '2024-03-10T10:00:00Z'
  },
  {
    id: '2',
    title: 'Car Maintenance',
    date: new Date(2024, 2, 10),
    type: 'maintenance',
    description: 'Regular maintenance check',
    budget: {
      expenses: [
        {
          id: 'exp3',
          description: 'Oil Change',
          amount: 80,
          category: 'Maintenance',
          quantity: 1,
          createdAt: '2024-03-10T10:00:00Z',
          updatedAt: '2024-03-10T10:00:00Z'
        }
      ],
      income: []
    },
    createdAt: '2024-03-10T10:00:00Z',
    updatedAt: '2024-03-10T10:00:00Z'
  },
  {
    id: '3',
    title: 'Local AutoX Event',
    date: new Date(2024, 3, 5),
    type: 'race',
    venue: 'Local Fairgrounds',
    description: 'Regional autocross competition',
    budget: {
      expenses: [
        {
          id: 'exp4',
          description: 'Entry Fee',
          amount: 75,
          category: 'Entry Fees',
          quantity: 1,
          createdAt: '2024-03-10T10:00:00Z',
          updatedAt: '2024-03-10T10:00:00Z'
        }
      ],
      income: []
    },
    createdAt: '2024-03-10T10:00:00Z',
    updatedAt: '2024-03-10T10:00:00Z'
  }
];