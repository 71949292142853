import React from 'react';
import { Link } from 'react-router-dom';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, Tooltip } from 'recharts';
import type { Budget } from '../../types/budget';

interface BudgetSummaryProps {
  budget: Budget;
}

export default function BudgetSummary({ budget }: BudgetSummaryProps) {
  // Calculate monthly data
  const monthlyData = Array.from({ length: 12 }, (_, i) => {
    const month = new Date(budget.seasonYear, i).toLocaleString('default', { month: 'short' });
    
    // Get events for this month
    const monthEvents = budget.events.filter(
      event => new Date(event.date).getMonth() === i
    );

    // Calculate expenses and income for events this month
    const eventExpenses = monthEvents.reduce(
      (sum, event) => sum + event.budget.expenses.reduce(
        (eventSum, expense) => eventSum + (expense.amount * expense.quantity), 0
      ), 0
    );

    const eventIncome = monthEvents.reduce(
      (sum, event) => sum + event.budget.income.reduce(
        (eventSum, income) => eventSum + (income.amount * income.quantity), 0
      ), 0
    );

    // Add monthly portion of season expenses/income
    const monthlySeasonExpenses = budget.seasonExpenses
      .filter(expense => expense.frequency === 'monthly' || 
             (expense.frequency === 'yearly' && i === 0) ||
             (expense.frequency === 'quarterly' && i % 3 === 0))
      .reduce((sum, expense) => {
        const amount = expense.amount * expense.quantity;
        if (expense.frequency === 'yearly') return sum + (amount / 12);
        if (expense.frequency === 'quarterly') return sum + (amount / 3);
        return sum + amount;
      }, 0);

    const monthlySeasonIncome = budget.seasonIncome
      .filter(income => income.frequency === 'monthly' ||
             (income.frequency === 'yearly' && i === 0) ||
             (income.frequency === 'quarterly' && i % 3 === 0))
      .reduce((sum, income) => {
        const amount = income.amount * income.quantity;
        if (income.frequency === 'yearly') return sum + (amount / 12);
        if (income.frequency === 'quarterly') return sum + (amount / 3);
        return sum + amount;
      }, 0);

    return {
      month,
      expenses: eventExpenses + monthlySeasonExpenses,
      income: eventIncome + monthlySeasonIncome,
      balance: (eventIncome + monthlySeasonIncome) - (eventExpenses + monthlySeasonExpenses)
    };
  });

  return (
    <div className="bg-dark-200 rounded-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-lg font-semibold text-white">Budget Overview</h2>
        <Link 
          to="/budget"
          className="text-sm text-primary-500 hover:text-primary-400"
        >
          View Details
        </Link>
      </div>

      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={monthlyData}>
            <defs>
              <linearGradient id="colorIncome" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#10B981" stopOpacity={0.1}/>
                <stop offset="95%" stopColor="#10B981" stopOpacity={0}/>
              </linearGradient>
              <linearGradient id="colorExpenses" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#EF4444" stopOpacity={0.1}/>
                <stop offset="95%" stopColor="#EF4444" stopOpacity={0}/>
              </linearGradient>
            </defs>
            <XAxis 
              dataKey="month" 
              stroke="#9CA3AF"
              tick={{ fill: '#9CA3AF' }}
            />
            <YAxis 
              stroke="#9CA3AF"
              tick={{ fill: '#9CA3AF' }}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: '#1F2937',
                border: 'none',
                borderRadius: '0.375rem',
                color: '#F3F4F6'
              }}
              formatter={(value: number) => `$${value.toLocaleString()}`}
            />
            <Area
              type="monotone"
              dataKey="income"
              stroke="#10B981"
              fillOpacity={1}
              fill="url(#colorIncome)"
            />
            <Area
              type="monotone"
              dataKey="expenses"
              stroke="#EF4444"
              fillOpacity={1}
              fill="url(#colorExpenses)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>

      <div className="grid grid-cols-3 gap-4 mt-6">
        <div className="text-center">
          <p className="text-sm text-gray-400">Monthly Avg. Income</p>
          <p className="text-lg font-semibold text-green-500">
            ${(monthlyData.reduce((sum, m) => sum + m.income, 0) / 12).toLocaleString()}
          </p>
        </div>
        <div className="text-center">
          <p className="text-sm text-gray-400">Monthly Avg. Expenses</p>
          <p className="text-lg font-semibold text-red-500">
            ${(monthlyData.reduce((sum, m) => sum + m.expenses, 0) / 12).toLocaleString()}
          </p>
        </div>
        <div className="text-center">
          <p className="text-sm text-gray-400">Net Balance</p>
          <p className={`text-lg font-semibold ${
            monthlyData.reduce((sum, m) => sum + m.balance, 0) >= 0
              ? 'text-green-500'
              : 'text-red-500'
          }`}>
            ${monthlyData.reduce((sum, m) => sum + m.balance, 0).toLocaleString()}
          </p>
        </div>
      </div>
    </div>
  );
}