import React from 'react';
import { Link } from 'react-router-dom';
import { Settings, AlertTriangle } from 'lucide-react';
import type { Vehicle } from '../../types/vehicle';

interface VehicleOverviewProps {
  vehicles: Vehicle[];
}

export default function VehicleOverview({ vehicles }: VehicleOverviewProps) {
  return (
    <div className="bg-dark-200 rounded-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-lg font-semibold text-white">Vehicle Status</h2>
        <Link 
          to="/vehicles"
          className="text-sm text-primary-500 hover:text-primary-400"
        >
          View All
        </Link>
      </div>

      <div className="space-y-4">
        {vehicles.length === 0 ? (
          <p className="text-gray-400 text-center py-4">No vehicles added</p>
        ) : (
          vehicles.map(vehicle => {
            // Calculate maintenance status (mock logic - replace with real logic later)
            const needsMaintenance = Math.random() > 0.5;
            const maintenanceItems = needsMaintenance ? Math.floor(Math.random() * 3) + 1 : 0;

            return (
              <Link
                key={vehicle.id}
                to={`/vehicles/${vehicle.id}`}
                className="block bg-dark-100 rounded-lg p-4 hover:bg-dark-50 transition-colors"
              >
                <div className="flex items-start gap-4">
                  <div className="flex-shrink-0 w-12 h-12 bg-dark-300 rounded-lg flex items-center justify-center">
                    <Settings className="w-6 h-6 text-primary-500" />
                  </div>
                  <div className="flex-1 min-w-0">
                    <h3 className="text-white font-medium truncate">
                      {vehicle.nickname || `${vehicle.year} ${vehicle.make} ${vehicle.model}`}
                    </h3>
                    <p className="text-sm text-gray-400">
                      {vehicle.type} • {vehicle.primaryUse}
                    </p>
                    {needsMaintenance && (
                      <div className="flex items-center gap-1 mt-2 text-sm text-yellow-500">
                        <AlertTriangle className="w-4 h-4" />
                        <span>{maintenanceItems} maintenance items due</span>
                      </div>
                    )}
                  </div>
                  <div className="flex-shrink-0 text-right">
                    <span className="text-sm font-medium text-white">
                      {vehicle.power} {vehicle.powerUnit}
                    </span>
                    <p className="text-xs text-gray-400">
                      {vehicle.weight} {vehicle.weightUnit}
                    </p>
                  </div>
                </div>
              </Link>
            );
          })
        )}
      </div>
    </div>
  );
}