import React, { useState } from 'react';
import { Package, AlertTriangle, ChevronDown, ChevronUp } from 'lucide-react';
import type { Part } from '../../types/part';
import type { Vehicle } from '../../types/vehicle';

interface InventoryCardProps {
  part: Part;
  vehicles: Vehicle[];
  onInstall: (partId: string, vehicleId: string) => void;
}

export default function InventoryCard({ part, vehicles, onInstall }: InventoryCardProps) {
  const [showDetails, setShowDetails] = useState(false);
  const [showInstallMenu, setShowInstallMenu] = useState(false);

  const isLowStock = part.minimumStock && part.quantity <= part.minimumStock;
  const isExpired = part.expirationDate && new Date(part.expirationDate) < new Date();

  return (
    <div className="bg-dark-200 rounded-lg p-4 hover:bg-dark-100 transition-colors">
      <div className="flex items-start gap-3">
        <div className="p-2 bg-primary-500/10 rounded-lg">
          <Package className="w-5 h-5 text-primary-500" />
        </div>
        <div className="flex-1 min-w-0">
          <div className="flex items-start justify-between">
            <div>
              <h3 className="text-white font-medium truncate">{part.name}</h3>
              <p className="text-sm text-gray-400">Qty: {part.quantity}</p>
            </div>
            {(isLowStock || isExpired) && (
              <AlertTriangle className="w-5 h-5 text-yellow-500" />
            )}
          </div>

          {showDetails && (
            <div className="mt-4 space-y-2 text-sm">
              {part.description && (
                <p className="text-gray-400">{part.description}</p>
              )}
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <p className="text-gray-500">Manufacturer</p>
                  <p className="text-white">{part.manufacturer || 'N/A'}</p>
                </div>
                <div>
                  <p className="text-gray-500">Part Number</p>
                  <p className="text-white">{part.partNumber || 'N/A'}</p>
                </div>
                <div>
                  <p className="text-gray-500">Condition</p>
                  <p className="text-white capitalize">{part.condition}</p>
                </div>
                <div>
                  <p className="text-gray-500">Location</p>
                  <p className="text-white">{part.location || 'N/A'}</p>
                </div>
              </div>
            </div>
          )}
        </div>
        <button
          onClick={() => setShowDetails(!showDetails)}
          className="p-1 text-gray-400 hover:text-primary-500"
        >
          {showDetails ? (
            <ChevronUp className="w-4 h-4" />
          ) : (
            <ChevronDown className="w-4 h-4" />
          )}
        </button>
      </div>

      <div className="mt-4 pt-4 border-t border-dark-50 flex items-center justify-between">
        <div className="text-sm">
          <span className="text-gray-400">Cost: </span>
          <span className="text-white">${part.cost.toLocaleString()}</span>
        </div>
        <div className="relative">
          <button
            onClick={() => setShowInstallMenu(!showInstallMenu)}
            className="px-3 py-1 bg-primary-500 text-white text-sm rounded hover:bg-primary-600"
          >
            Install
          </button>
          {showInstallMenu && (
            <div className="absolute right-0 bottom-full mb-2 w-48 bg-dark-100 rounded-md shadow-lg overflow-hidden z-10">
              {vehicles.map(vehicle => (
                <button
                  key={vehicle.id}
                  onClick={() => {
                    onInstall(part.id, vehicle.id);
                    setShowInstallMenu(false);
                  }}
                  className="w-full px-4 py-2 text-left text-sm text-white hover:bg-dark-50"
                >
                  {vehicle.nickname || `${vehicle.year} ${vehicle.make} ${vehicle.model}`}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}