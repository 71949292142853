import { geminiModel } from '../config/firebase';

interface TrackTip {
  tip: string;
  category: 'surface' | 'strategy' | 'weather' | 'general';
}

interface AITrackTipsResponse {
  tips: TrackTip[];
}

export async function generateTrackTips(location: string): Promise<TrackTip[]> {
  try {
    const prompt = `
      As a motorsport expert, provide specific tips for racing at ${location}.
      Focus on track conditions, racing strategy, and weather considerations.
      
      Provide the response in the following JSON format:
      {
        "tips": [
          {
            "tip": "specific tip here",
            "category": "one of: surface, strategy, weather, general"
          }
        ]
      }
      
      Provide exactly 3 tips, each in a different category.
      Make the tips specific to the location and actionable for racers.
      Return ONLY the JSON with no markdown formatting.
    `;

    const result = await geminiModel.generateContent(prompt);
    const response = result.response;
    const text = response.text();
    
    try {
      // Clean up the response by removing markdown code blocks
      const cleanJson = text.replace(/```json\n?|\n?```/g, '').trim();
      const parsedResponse: AITrackTipsResponse = JSON.parse(cleanJson);
      return parsedResponse.tips;
    } catch (error) {
      console.error('Failed to parse AI response:', error);
      console.error('Raw response:', text);
      return [];
    }
  } catch (error) {
    console.error('Error generating track tips:', error);
    return [];
  }
} 