import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ArrowLeft, Save, X, Lightbulb, Loader2 } from 'lucide-react';
import type { Event, EventType } from '../types/event';
import EventLocationSearch from '../components/events/EventLocationSearch';
import EventExpenses from '../components/events/EventExpenses';
import EventChecklist from '../components/events/EventChecklist';
import EventSetup from '../components/events/EventSetup';
import { format } from 'date-fns';
import { generateTrackTips } from '../services/aiService';

const eventTypes: EventType[] = [
  'competition',
  'practice',
  'maintenance',
  'education',
  'exhibition',
  'testing',
  'media',
  'development',
  'sim',
  'other'
];

const defaultEvent: Omit<Event, 'id'> = {
  title: '',
  date: new Date(),
  type: 'competition',
  description: '',
  budget: {
    expenses: [],
    income: []
  },
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString()
};

interface EventDetailProps {
  events: Event[];
  onUpdateEvent: (event: Event) => void;
}

export default function EventDetail({ events, onUpdateEvent }: EventDetailProps) {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const isNew = id === 'new';
  const [isEditing, setIsEditing] = useState(isNew);
  
  // For new events, use the date from location state or current date
  const initialDate = location.state?.date ? new Date(location.state.date) : new Date();
  
  const [event, setEvent] = useState<Event | undefined>(
    isNew 
      ? { ...defaultEvent, id: crypto.randomUUID(), date: initialDate }
      : events.find(e => e.id === id)
  );
  const [editedEvent, setEditedEvent] = useState<Event | undefined>(event);
  const [trackTips, setTrackTips] = useState<Array<{ tip: string; category: string }>>([]);
  const [isLoadingTips, setIsLoadingTips] = useState(false);

  useEffect(() => {
    if (!isNew && !event) {
      navigate('/schedule');
    }
  }, [event, navigate, isNew]);

  useEffect(() => {
    async function fetchTrackTips() {
      if (editedEvent?.location) {
        setIsLoadingTips(true);
        try {
          const locationString = editedEvent.location.name || 
                               editedEvent.location.address || 
                               'Unknown Location';
                             
          console.log('Fetching tips for location:', locationString);
          const tips = await generateTrackTips(locationString);
          setTrackTips(tips);
        } catch (error) {
          console.error('Failed to fetch track tips:', error);
        } finally {
          setIsLoadingTips(false);
        }
      }
    }

    fetchTrackTips();
  }, [editedEvent?.location]);

  if (!event || !editedEvent) {
    return null;
  }

  const handleSave = () => {
    if (editedEvent) {
      onUpdateEvent(editedEvent);
      setEvent(editedEvent);
      setIsEditing(false);
      navigate('/schedule');
    }
  };

  const handleCancel = () => {
    if (isNew) {
      navigate('/schedule');
    } else {
      setEditedEvent(event);
      setIsEditing(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <button
            onClick={() => navigate('/schedule')}
            className="p-2 text-gray-400 hover:text-primary-500 rounded-lg hover:bg-dark-50"
          >
            <ArrowLeft className="w-5 h-5" />
          </button>
          <h1 className="text-2xl font-bold text-white">
            {isNew ? 'New Event' : editedEvent.title}
          </h1>
        </div>
        <div className="flex gap-2">
          <button
            onClick={handleSave}
            className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600"
          >
            <Save className="w-4 h-4" />
            {isNew ? 'Create Event' : 'Save Changes'}
          </button>
          <button
            onClick={handleCancel}
            className="flex items-center gap-2 px-4 py-2 bg-dark-100 text-gray-400 rounded-md hover:bg-dark-50"
          >
            <X className="w-4 h-4" />
            Cancel
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Main Content */}
        <div className="lg:col-span-2 space-y-6">
          {/* Event Details */}
          <div className="bg-dark-200 rounded-lg p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-400 mb-1">
                  Event Title
                </label>
                <input
                  type="text"
                  value={editedEvent.title}
                  onChange={e =>
                    setEditedEvent(prev =>
                      prev ? { ...prev, title: e.target.value } : prev
                    )
                  }
                  className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-400 mb-1">
                  Event Type
                </label>
                <select
                  value={editedEvent.type}
                  onChange={e =>
                    setEditedEvent(prev =>
                      prev ? { ...prev, type: e.target.value as EventType } : prev
                    )
                  }
                  className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                >
                  {eventTypes.map(type => (
                    <option key={type} value={type}>
                      {type.charAt(0).toUpperCase() + type.slice(1)}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-400 mb-1">
                  Date
                </label>
                <input
                  type="datetime-local"
                  value={format(new Date(editedEvent.date), "yyyy-MM-dd'T'HH:mm")}
                  onChange={e =>
                    setEditedEvent(prev =>
                      prev ? { ...prev, date: new Date(e.target.value) } : prev
                    )
                  }
                  className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-400 mb-1">
                  Description
                </label>
                <textarea
                  value={editedEvent.description || ''}
                  onChange={e =>
                    setEditedEvent(prev =>
                      prev ? { ...prev, description: e.target.value } : prev
                    )
                  }
                  rows={3}
                  className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                />
              </div>
            </div>
          </div>

          {/* Location */}
          <EventLocationSearch
            location={editedEvent.location}
            isEditing={true}
            onChange={location =>
              setEditedEvent(prev =>
                prev ? { ...prev, location } : prev
              )
            }
          />

          {/* Expenses */}
          <EventExpenses
            budget={editedEvent.budget}
            isEditing={true}
            onChange={budget =>
              setEditedEvent(prev =>
                prev ? { ...prev, budget } : prev
              )
            }
          />
        </div>

        {/* Sidebar */}
        <div className="space-y-6">
          {/* Checklist */}
          <EventChecklist
            checklistId={editedEvent.checklistId}
            isEditing={true}
            onChange={checklistId =>
              setEditedEvent(prev =>
                prev ? { ...prev, checklistId } : prev
              )
            }
          />

          {/* Setup */}
          <EventSetup
            setupId={editedEvent.setupId}
            isEditing={true}
            onChange={setupId =>
              setEditedEvent(prev =>
                prev ? { ...prev, setupId } : prev
              )
            }
          />

          {/* AI Tips */}
          <div className="bg-dark-200 rounded-lg p-6">
            <div className="flex items-center gap-2 mb-4">
              <Lightbulb className="w-5 h-5 text-primary-500" />
              <h2 className="text-lg font-semibold text-white">AI Track Tips</h2>
            </div>
            
            {editedEvent.location ? (
              <div className="space-y-3">
                {isLoadingTips ? (
                  <div className="flex justify-center py-4">
                    <Loader2 className="w-6 h-6 animate-spin text-primary-500" />
                  </div>
                ) : (
                  <>
                    <ul className="space-y-2 text-gray-400">
                      {trackTips.map((tip, index) => (
                        <li key={index} className="flex items-start gap-2">
                          <span className="text-primary-500 mt-1">•</span>
                          <span>{tip.tip}</span>
                        </li>
                      ))}
                    </ul>
                    <div className="text-xs text-gray-500 mt-4">
                      Tips generated by AI based on track data and historical events
                    </div>
                  </>
                )}
              </div>
            ) : (
              <p className="text-gray-400 text-sm">
                Add an event location to get AI-powered track insights
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}