import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, Import } from 'lucide-react';
import { CalendarHeader } from '../components/calendar/CalendarHeader';
import { MonthView } from '../components/calendar/MonthView';
import { WeekView } from '../components/calendar/WeekView';
import { DayView } from '../components/calendar/DayView';
import { YearView } from '../components/calendar/YearView';
import { EventsOverview } from '../components/calendar/EventsOverview';
import { MOCK_EVENTS } from '../data/mockData';
import type { Event } from '../types/event';

type ViewType = 'month' | 'week' | 'day' | 'year';

interface ScheduleProps {
  events: Event[];
  setEvents: React.Dispatch<React.SetStateAction<Event[]>>;
}

export default function Schedule({ events, setEvents }: ScheduleProps) {
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = React.useState(new Date());
  const [view, setView] = React.useState<ViewType>('month');

  React.useEffect(() => {
    if (events.length === 0) {
      setEvents(MOCK_EVENTS);
    }
  }, [events.length, setEvents]);

  const handleDateSelect = (date: Date) => {
    navigate('/schedule/new', { state: { date } });
  };

  const handleEventSelect = (event: Event) => {
    navigate(`/schedule/${event.id}`);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-white">Race Schedule</h1>
        <div className="flex items-center gap-2">
          <button
            onClick={() => navigate('/schedule/new')}
            className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600"
          >
            <Plus className="w-4 h-4" />
            Add Event
          </button>
          <button
            onClick={() => alert('Import functionality coming soon!')}
            className="flex items-center gap-2 px-4 py-2 bg-dark-100 text-white rounded-md hover:bg-dark-50"
          >
            <Import className="w-4 h-4" />
            Import Schedule
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2">
          {/* Calendar View */}
          <div className="bg-dark-200 rounded-lg">
            <CalendarHeader
              currentDate={currentDate}
              onDateChange={setCurrentDate}
              view={view}
              onViewChange={setView}
            />

            <div className="p-4">
              {view === 'month' && (
                <MonthView
                  currentDate={currentDate}
                  events={events}
                  onSelectDate={handleDateSelect}
                  onSelectEvent={handleEventSelect}
                />
              )}
              {view === 'week' && (
                <WeekView
                  currentDate={currentDate}
                  events={events}
                  onSelectDate={handleDateSelect}
                  onSelectEvent={handleEventSelect}
                />
              )}
              {view === 'day' && (
                <DayView
                  currentDate={currentDate}
                  events={events}
                  onSelectDate={handleDateSelect}
                  onSelectEvent={handleEventSelect}
                />
              )}
              {view === 'year' && (
                <YearView
                  currentDate={currentDate}
                  events={events}
                  onSelectDate={handleDateSelect}
                  onSelectEvent={handleEventSelect}
                />
              )}
            </div>
          </div>
        </div>

        <div>
          <EventsOverview 
            events={events}
            onSelectEvent={handleEventSelect}
          />
        </div>
      </div>
    </div>
  );
}