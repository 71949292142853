import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Plus, Edit, Trash2, Save, X, Car, Calendar, Sparkles } from 'lucide-react';
import type { Checklist, ChecklistCategory, ChecklistItem } from '../types/checklist';
import type { Vehicle } from '../types/vehicle';
import type { Event } from '../types/event';
import { formatDistanceToNow } from 'date-fns';

const categories: ChecklistCategory[] = [
  'Event Prep',
  'Track Setup',
  'Maintenance',
  'Packing List',
  'Documentation',
  'Safety',
  'Sponsorship',
  'Other'
];

interface ChecklistDetailProps {
  checklists: Checklist[];
  onUpdateChecklist: (checklist: Checklist) => void;
  vehicles: Vehicle[];
  events: Event[];
}

export default function ChecklistDetail({ 
  checklists, 
  onUpdateChecklist,
  vehicles,
  events 
}: ChecklistDetailProps) {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const isNew = id === 'new';
  
  const [formData, setFormData] = useState<Checklist>({
    id: isNew ? crypto.randomUUID() : id!,
    name: '',
    category: 'Event Prep',
    description: '',
    items: [],
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  });

  const [newItemText, setNewItemText] = useState('');
  const [newItemNotes, setNewItemNotes] = useState('');
  const [editingItem, setEditingItem] = useState<string | null>(null);

  React.useEffect(() => {
    if (!isNew) {
      const checklist = checklists.find(c => c.id === id);
      if (checklist) {
        setFormData(checklist);
      } else {
        navigate('/checklists');
      }
    }
  }, [id, checklists, navigate, isNew]);

  const handleAddItem = (e: React.FormEvent) => {
    e.preventDefault();
    if (!newItemText.trim()) return;

    const newItem: ChecklistItem = {
      id: crypto.randomUUID(),
      text: newItemText.trim(),
      notes: newItemNotes.trim() || undefined,
      completed: false,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    setFormData(prev => ({
      ...prev,
      items: [...prev.items, newItem],
      updatedAt: new Date().toISOString(),
    }));

    setNewItemText('');
    setNewItemNotes('');
  };

  const handleToggleItem = (itemId: string) => {
    setFormData(prev => ({
      ...prev,
      items: prev.items.map(item =>
        item.id === itemId
          ? { ...item, completed: !item.completed, updatedAt: new Date().toISOString() }
          : item
      ),
      updatedAt: new Date().toISOString(),
    }));
  };

  const handleUpdateItem = (itemId: string, updates: Partial<ChecklistItem>) => {
    setFormData(prev => ({
      ...prev,
      items: prev.items.map(item =>
        item.id === itemId
          ? { ...item, ...updates, updatedAt: new Date().toISOString() }
          : item
      ),
      updatedAt: new Date().toISOString(),
    }));
  };

  const handleDeleteItem = (itemId: string) => {
    if (!window.confirm('Are you sure you want to delete this item?')) return;

    setFormData(prev => ({
      ...prev,
      items: prev.items.filter(item => item.id !== itemId),
      updatedAt: new Date().toISOString(),
    }));
  };

  const handleSave = () => {
    if (!formData.name) return;
    onUpdateChecklist(formData);
    navigate('/checklists');
  };

  const handleSmartSuggestions = () => {
    // This would be replaced with actual AI logic later
    const suggestedItems = [
      {
        id: crypto.randomUUID(),
        text: 'Check tire pressures (cold)',
        completed: false,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      },
      {
        id: crypto.randomUUID(),
        text: 'Inspect brake pad thickness',
        completed: false,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      }
    ];

    setFormData(prev => ({
      ...prev,
      items: [...prev.items, ...suggestedItems],
      updatedAt: new Date().toISOString()
    }));
  };

  const completedItems = formData.items.filter(item => item.completed).length;
  const progress = formData.items.length > 0 
    ? (completedItems / formData.items.length) * 100 
    : 0;

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <button
            onClick={() => navigate('/checklists')}
            className="p-2 text-gray-400 hover:text-primary-500 rounded-lg hover:bg-dark-50"
          >
            <ArrowLeft className="w-5 h-5" />
          </button>
          <h1 className="text-2xl font-bold text-white">
            {isNew ? 'New Checklist' : formData.name}
          </h1>
        </div>
        <div className="flex gap-2">
          <button
            onClick={handleSave}
            className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600"
          >
            <Save className="w-4 h-4" />
            {isNew ? 'Create Checklist' : 'Save Changes'}
          </button>
          <button
            onClick={() => navigate('/checklists')}
            className="flex items-center gap-2 px-4 py-2 bg-dark-100 text-gray-400 rounded-md hover:bg-dark-50"
          >
            <X className="w-4 h-4" />
            Cancel
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Main Content */}
        <div className="lg:col-span-2 space-y-6">
          {/* Basic Info */}
          <div className="bg-dark-200 rounded-lg p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-400 mb-1">
                  Name
                </label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData(prev => ({ 
                    ...prev, 
                    name: e.target.value,
                    updatedAt: new Date().toISOString()
                  }))}
                  className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                  placeholder="e.g., Track Day Preparation"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-400 mb-1">
                  Category
                </label>
                <select
                  value={formData.category}
                  onChange={(e) => setFormData(prev => ({ 
                    ...prev, 
                    category: e.target.value as ChecklistCategory,
                    updatedAt: new Date().toISOString()
                  }))}
                  className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                >
                  {categories.map(category => (
                    <option key={category} value={category}>{category}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Description (Optional)
              </label>
              <textarea
                value={formData.description}
                onChange={(e) => setFormData(prev => ({ 
                  ...prev, 
                  description: e.target.value,
                  updatedAt: new Date().toISOString()
                }))}
                rows={3}
                className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                placeholder="Add a description for this checklist..."
              />
            </div>
          </div>

          {/* Checklist Items */}
          <div className="bg-dark-200 rounded-lg p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-lg font-semibold text-white">Items</h2>
              <div className="flex gap-2">
                <button
                  onClick={handleSmartSuggestions}
                  className="flex items-center gap-2 px-3 py-1 bg-dark-100 text-gray-400 rounded-md hover:bg-dark-50 group relative"
                >
                  <Sparkles className="w-4 h-4 text-primary-500" />
                  Smart Add
                  <span className="absolute hidden group-hover:block right-0 top-full mt-2 w-64 p-2 bg-dark-100 text-xs text-gray-400 rounded-md z-10">
                    Add AI-suggested items based on vehicle, event type, and historical data
                  </span>
                </button>
                <button
                  onClick={() => setNewItemText('')}
                  className="flex items-center gap-2 px-3 py-1 bg-dark-100 text-white rounded-md hover:bg-dark-50"
                >
                  <Plus className="w-4 h-4" />
                  Add Item
                </button>
              </div>
            </div>

            {/* Add Item Form */}
            <form onSubmit={handleAddItem} className="mb-6">
              <div className="space-y-4">
                <input
                  type="text"
                  value={newItemText}
                  onChange={(e) => setNewItemText(e.target.value)}
                  placeholder="Add new item..."
                  className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                />
                <textarea
                  value={newItemNotes}
                  onChange={(e) => setNewItemNotes(e.target.value)}
                  placeholder="Add notes (optional)..."
                  rows={2}
                  className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                />
                <div className="flex justify-end">
                  <button
                    type="submit"
                    disabled={!newItemText.trim()}
                    className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <Plus className="w-4 h-4" />
                    Add Item
                  </button>
                </div>
              </div>
            </form>

            {/* Items List */}
            <div className="space-y-2">
              {formData.items.length === 0 ? (
                <p className="text-gray-400 text-center py-4">No items in this checklist yet.</p>
              ) : (
                formData.items.map((item) => (
                  <div
                    key={item.id}
                    className="bg-dark-100 rounded-lg p-4 hover:bg-dark-50"
                  >
                    <div className="flex items-start gap-4">
                      <input
                        type="checkbox"
                        checked={item.completed}
                        onChange={() => handleToggleItem(item.id)}
                        className="mt-1 rounded border-dark-50 bg-dark-300 text-primary-500 focus:ring-primary-500"
                      />
                      <div className="flex-1">
                        {editingItem === item.id ? (
                          <div className="space-y-2">
                            <input
                              type="text"
                              value={item.text}
                              onChange={(e) =>
                                handleUpdateItem(item.id, { text: e.target.value })
                              }
                              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                            />
                            <textarea
                              value={item.notes || ''}
                              onChange={(e) =>
                                handleUpdateItem(item.id, { notes: e.target.value })
                              }
                              placeholder="Add notes..."
                              rows={2}
                              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                            />
                            <div className="flex justify-end gap-2">
                              <button
                                onClick={() => setEditingItem(null)}
                                className="px-3 py-1 text-sm text-gray-400 hover:text-gray-300"
                              >
                                Cancel
                              </button>
                              <button
                                onClick={() => setEditingItem(null)}
                                className="px-3 py-1 text-sm bg-primary-500 text-white rounded-md hover:bg-primary-600"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        ) : (
                          <>
                            <p className={`text-white ${item.completed ? 'line-through text-gray-400' : ''}`}>
                              {item.text}
                            </p>
                            {item.notes && (
                              <p className="mt-1 text-sm text-gray-400">{item.notes}</p>
                            )}
                          </>
                        )}
                      </div>
                      {editingItem !== item.id && (
                        <div className="flex gap-2">
                          <button
                            onClick={() => setEditingItem(item.id)}
                            className="p-1 text-gray-400 hover:text-primary-500"
                          >
                            <Edit className="w-4 h-4" />
                          </button>
                          <button
                            onClick={() => handleDeleteItem(item.id)}
                            className="p-1 text-gray-400 hover:text-red-500"
                          >
                            <Trash2 className="w-4 h-4" />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        {/* Sidebar */}
        <div className="space-y-6">
          {/* Associated Vehicle */}
          <div className="bg-dark-200 rounded-lg p-6">
            <div className="flex items-center gap-2 mb-4">
              <Car className="w-5 h-5 text-primary-500" />
              <h2 className="text-lg font-semibold text-white">Associated Vehicle</h2>
            </div>
            <select
              value={formData.vehicleId || ''}
              onChange={(e) => {
                const newVehicleId = e.target.value || undefined;
                setFormData(prev => ({ 
                  ...prev, 
                  vehicleId: newVehicleId,
                  // Clear event if vehicle changes
                  eventId: newVehicleId ? prev.eventId : undefined,
                  updatedAt: new Date().toISOString()
                }));
              }}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
            >
              <option value="">Select a vehicle</option>
              {vehicles.map(vehicle => (
                <option key={vehicle.id} value={vehicle.id}>
                  {vehicle.nickname || `${vehicle.year} ${vehicle.make} ${vehicle.model}`}
                </option>
              ))}
            </select>
            {formData.vehicleId && (
              <p className="mt-2 text-xs text-gray-400">
                Items will be customized based on vehicle type and specifications
              </p>
            )}
          </div>

          {/* Associated Event */}
          <div className="bg-dark-200 rounded-lg p-6">
            <div className="flex items-center gap-2 mb-4">
              <Calendar className="w-5 h-5 text-primary-500" />
              <h2 className="text-lg font-semibold text-white">Associated Event</h2>
            </div>
            <select
              value={formData.eventId || ''}
              onChange={(e) => setFormData(prev => ({ 
                ...prev, 
                eventId: e.target.value || undefined,
                updatedAt: new Date().toISOString()
              }))}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
              disabled={!formData.vehicleId}
            >
              <option value="">Select an event</option>
              {events
                .filter(event => !event.vehicleId || event.vehicleId === formData.vehicleId)
                .map(event => (
                  <option key={event.id} value={event.id}>
                    {event.title}
                  </option>
                ))
              }
            </select>
            {formData.eventId && (
              <p className="mt-2 text-xs text-gray-400">
                Checklist will adapt based on event type and requirements
              </p>
            )}
          </div>

          {/* Progress */}
          <div className="bg-dark-200 rounded-lg p-6">
            <h2 className="text-lg font-semibold text-white mb-4">Progress</h2>
            <div className="space-y-2">
              <div className="flex justify-between text-sm">
                <span className="text-gray-400">Completed</span>
                <span className="text-white">{completedItems}/{formData.items.length} items</span>
              </div>
              <div className="h-2 bg-dark-300 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-primary-500 rounded-full transition-all duration-300"
                  style={{ width: `${progress}%` }}
                />
              </div>
            </div>
          </div>

          {/* Metadata */}
          {!isNew && (
            <div className="bg-dark-200 rounded-lg p-6">
              <h2 className="text-lg font-semibold text-white mb-4">Details</h2>
              <div className="space-y-4 text-sm">
                <div>
                  <p className="text-gray-400">Created</p>
                  <p className="text-white">
                    {formatDistanceToNow(new Date(formData.createdAt))} ago
                  </p>
                </div>
                <div>
                  <p className="text-gray-400">Last Updated</p>
                  <p className="text-white">
                    {formatDistanceToNow(new Date(formData.updatedAt))} ago
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}