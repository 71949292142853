import React from 'react';
import { TrendingUp, AlertTriangle, Calendar, DollarSign } from 'lucide-react';
import type { Event } from '../../types/event';
import type { Vehicle } from '../../types/vehicle';
import type { Task } from '../../types/task';
import type { Budget } from '../../types/budget';

interface DashboardInsightsProps {
  events: Event[];
  vehicles: Vehicle[];
  tasks: Task[];
  budget: Budget;
}

export default function DashboardInsights({ events, vehicles, tasks, budget }: DashboardInsightsProps) {
  // Calculate some basic metrics for insights
  const upcomingEvents = events.filter(e => new Date(e.date) > new Date());
  const maintenanceTasks = tasks.filter(t => t.labels?.includes('maintenance'));
  const totalBudget = budget.seasonIncome.reduce((sum, income) => sum + income.amount, 0);
  const usedBudget = budget.seasonExpenses.reduce((sum, expense) => sum + expense.amount, 0);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {/* Season Progress */}
      <div className="space-y-4">
        <div className="flex items-center gap-2">
          <TrendingUp className="w-5 h-5 text-primary-500" />
          <h3 className="text-lg font-medium text-white">Season Analysis</h3>
        </div>
        <div className="space-y-2">
          <div className="flex justify-between text-sm">
            <span className="text-gray-400">Season Progress</span>
            <span className="text-white">45%</span>
          </div>
          <div className="h-2 bg-dark-300 rounded-full overflow-hidden">
            <div 
              className="h-full bg-primary-500 rounded-full"
              style={{ width: '45%' }}
            />
          </div>
          <p className="text-sm text-gray-300 mt-2">
            You're slightly ahead of your performance targets for this point in the season
          </p>
          <button className="text-sm text-primary-500 opacity-50 cursor-not-allowed">
            View detailed analysis
          </button>
        </div>
      </div>

      {/* AI Insights */}
      <div className="space-y-4">
        <div className="grid grid-cols-1 gap-4">
          {/* Schedule Optimization */}
          <div className="flex items-start gap-3">
            <Calendar className="w-5 h-5 text-primary-500 shrink-0 mt-1" />
            <div>
              <p className="text-gray-300">
                Consider spacing out your {upcomingEvents.length} upcoming events to allow more maintenance windows
              </p>
              <button className="mt-1 text-sm text-primary-500 opacity-50 cursor-not-allowed">
                View schedule suggestions
              </button>
            </div>
          </div>

          {/* Budget Insights */}
          <div className="flex items-start gap-3">
            <DollarSign className="w-5 h-5 text-primary-500 shrink-0 mt-1" />
            <div>
              <p className="text-gray-300">
                Current spending rate suggests you'll be under budget by season end
              </p>
              <button className="mt-1 text-sm text-primary-500 opacity-50 cursor-not-allowed">
                View reinvestment options
              </button>
            </div>
          </div>

          {/* Maintenance Warning */}
          {maintenanceTasks.length > 0 && (
            <div className="flex items-start gap-3">
              <AlertTriangle className="w-5 h-5 text-primary-500 shrink-0 mt-1" />
              <div>
                <p className="text-gray-300">
                  {maintenanceTasks.length} maintenance items should be addressed before your next event
                </p>
                <button className="mt-1 text-sm text-primary-500 opacity-50 cursor-not-allowed">
                  Generate maintenance plan
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Preview Message */}
        <div className="text-xs text-gray-500 pt-4 border-t border-dark-50">
          AI insights are in preview. Analysis will use historical performance data and patterns when available.
        </div>
      </div>
    </div>
  );
}