import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Plus } from 'lucide-react';
import ChecklistCard from '../components/checklists/ChecklistCard';
import type { Checklist } from '../types/checklist';

// Example checklists
const initialChecklists: Checklist[] = [
  {
    id: '1',
    name: 'Winter Storage Checklist',
    category: 'Maintenance',
    description: 'Complete checklist for preparing vehicle for winter storage',
    items: [
      {
        id: 'ws1',
        text: 'Change oil and filter',
        completed: false,
        notes: 'Use synthetic oil for better protection during storage',
        createdAt: '2024-03-10T10:00:00Z',
        updatedAt: '2024-03-10T10:00:00Z'
      },
      {
        id: 'ws2',
        text: 'Fill fuel tank and add fuel stabilizer',
        completed: false,
        createdAt: '2024-03-10T10:00:00Z',
        updatedAt: '2024-03-10T10:00:00Z'
      }
    ],
    createdAt: '2024-03-10T10:00:00Z',
    updatedAt: '2024-03-10T10:00:00Z'
  },
  {
    id: '2',
    name: 'Track Day Preparation',
    category: 'Event Prep',
    description: 'Essential checks before track day',
    items: [
      {
        id: 'td1',
        text: 'Check tire pressures',
        completed: false,
        createdAt: '2024-03-10T10:00:00Z',
        updatedAt: '2024-03-10T10:00:00Z'
      }
    ],
    createdAt: '2024-03-10T10:00:00Z',
    updatedAt: '2024-03-10T10:00:00Z'
  }
];

interface ChecklistsProps {
  checklists: Checklist[];
  setChecklists: React.Dispatch<React.SetStateAction<Checklist[]>>;
}

export default function Checklists({ checklists, setChecklists }: ChecklistsProps) {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (checklists.length === 0) {
      setChecklists(initialChecklists);
    }
  }, [checklists.length, setChecklists]);

  const handleDeleteChecklist = (id: string) => {
    if (window.confirm('Are you sure you want to delete this checklist?')) {
      setChecklists(prev => prev.filter(checklist => checklist.id !== id));
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-white">Checklists</h1>
        <Link
          to="/checklists/new"
          className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600"
        >
          <Plus className="w-5 h-5" />
          New Checklist
        </Link>
      </div>

      {checklists.length === 0 ? (
        <div className="text-center py-12">
          <p className="text-gray-400">No checklists created yet.</p>
          <Link
            to="/checklists/new"
            className="mt-4 text-primary-500 hover:text-primary-400"
          >
            Create your first checklist
          </Link>
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {checklists.map(checklist => (
            <ChecklistCard
              key={checklist.id}
              checklist={checklist}
              onEdit={() => navigate(`/checklists/${checklist.id}`)}
              onDelete={handleDeleteChecklist}
            />
          ))}
        </div>
      )}
    </div>
  );
}