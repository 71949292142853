import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Edit, ArrowLeft, Plus, Trash2 } from 'lucide-react';
import type { Vehicle, Modification, ModificationType } from '../types/vehicle';
import type { VehicleSetup } from '../types/vehicleSetup';
import VehicleSetupComponent from '../components/vehicles/setup/VehicleSetup';
import type { Part } from '../types/part';
import { useDroppable } from '@dnd-kit/core';

const modificationTypes: ModificationType[] = [
  'Engine',
  'Suspension',
  'Brakes',
  'Transmission',
  'Exterior',
  'Interior',
  'Wheels/Tires',
  'Other'
];

interface VehicleDetailProps {
  vehicles: Vehicle[];
  onUpdateVehicle: (vehicle: Vehicle) => void;
}

export default function VehicleDetail({ vehicles, onUpdateVehicle }: VehicleDetailProps) {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const vehicle = vehicles.find(v => v.id === id);
  
  const [isEditing, setIsEditing] = useState(false);
  const [editedVehicle, setEditedVehicle] = useState<Vehicle | undefined>(vehicle);
  const [newModification, setNewModification] = useState({
    type: 'Engine' as ModificationType,
    name: '',
    description: '',
    cost: undefined as number | undefined,
    date: new Date().toISOString().split('T')[0]
  });

  const { setNodeRef } = useDroppable({
    id: vehicle.id,
  });

  if (!vehicle || !editedVehicle) {
    return (
      <div className="text-center py-12">
        <p className="text-gray-400">Vehicle not found.</p>
        <button
          onClick={() => navigate('/garage')}
          className="mt-4 text-primary-500 hover:text-primary-400"
        >
          Return to Garage
        </button>
      </div>
    );
  }

  // Initialize setup if it doesn't exist
  if (!editedVehicle.setup) {
    editedVehicle.setup = {
      id: '1',
      name: 'Default Setup',
      alignment: {
        frontCamber: -2.5,
        rearCamber: -2.0,
        frontToe: 0.1,
        rearToe: 0.2,
        caster: 7.0
      },
      suspension: {
        frontRideHeight: 100,
        rearRideHeight: 105,
        frontSpringRate: 500,
        rearSpringRate: 450,
        frontRebound: 8,
        rearRebound: 8,
        frontCompression: 6,
        rearCompression: 6
      },
      brakes: {
        brakeBias: 60,
        frontPressure: 35,
        rearPressure: 32
      },
      tires: {
        frontPressureCold: 30,
        rearPressureCold: 28,
        frontPressureHot: 35,
        rearPressureHot: 33
      },
      weight: {
        cornerWeights: {
          frontLeft: editedVehicle.weight / 4,
          frontRight: editedVehicle.weight / 4,
          rearLeft: editedVehicle.weight / 4,
          rearRight: editedVehicle.weight / 4
        },
        ballast: 0,
        ballastPosition: 'N/A'
      },
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };
  }

  const calculatePowerToWeight = () => {
    const weight = vehicle.weightUnit === 'kg' ? vehicle.weight * 2.20462 : vehicle.weight;
    const power = vehicle.powerUnit === 'PS' ? vehicle.power * 0.9863 : 
                 vehicle.powerUnit === 'kW' ? vehicle.power * 1.34102 : 
                 vehicle.power;
    return (weight / power).toFixed(2);
  };

  const totalSpent = vehicle.modifications?.reduce((sum, mod) => sum + (mod.cost || 0), 0) || 0;

  const handleSave = () => {
    if (editedVehicle) {
      onUpdateVehicle(editedVehicle);
      setIsEditing(false);
    }
  };

  const handleUpdateSetup = (updatedSetup: VehicleSetup) => {
    const updatedVehicle = {
      ...editedVehicle,
      setup: updatedSetup,
      weight: Object.values(updatedSetup.weight.cornerWeights).reduce((a, b) => a + b, 0) + updatedSetup.weight.ballast
    };
    setEditedVehicle(updatedVehicle);
    onUpdateVehicle(updatedVehicle);
  };

  const handleAddModification = () => {
    if (editedVehicle && newModification.name && newModification.cost !== undefined) {
      const modification: Part = {
        id: Math.random().toString(36).substr(2, 9),
        type: newModification.type,
        name: newModification.name,
        description: newModification.description,
        cost: newModification.cost,
        quantity: 1, // Assuming each modification is a single item
        condition: 'new', // Default condition
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        budgetCategory: 'Parts', // Default category
        // Add other necessary fields with default values or from newModification
      };

      const updatedVehicle = {
        ...editedVehicle,
        modifications: [...(editedVehicle.modifications || []), modification]
      };

      setEditedVehicle(updatedVehicle);
      onUpdateVehicle(updatedVehicle);

      // Reset form
      setNewModification({
        type: 'Engine',
        name: '',
        description: '',
        cost: undefined,
        date: new Date().toISOString().split('T')[0]
      });
    }
  };

  const handleDeleteModification = (modId: string) => {
    if (editedVehicle) {
      const updatedVehicle = {
        ...editedVehicle,
        modifications: editedVehicle.modifications?.filter(mod => mod.id !== modId)
      };
      setEditedVehicle(updatedVehicle);
      onUpdateVehicle(updatedVehicle);
    }
  };

  return (
    <div ref={setNodeRef} className="space-y-6">
      <div className="flex items-center gap-4">
        <button
          onClick={() => navigate('/garage')}
          className="p-2 text-gray-400 hover:text-primary-500 rounded-lg hover:bg-dark-50"
        >
          <ArrowLeft className="w-5 h-5" />
        </button>
        <h1 className="text-2xl font-bold text-white">
          {vehicle.nickname || `${vehicle.year} ${vehicle.make} ${vehicle.model}`}
        </h1>
      </div>

      {/* Vehicle Summary Card */}
      <div className="bg-dark-200 rounded-lg p-6">
        <div className="flex justify-between items-start mb-6">
          <div>
            <h2 className="text-xl font-semibold text-white">Vehicle Summary</h2>
            {!vehicle.nickname && (
              <p className="text-gray-400">{vehicle.year} {vehicle.make} {vehicle.model}</p>
            )}
          </div>
          <button
            onClick={() => setIsEditing(!isEditing)}
            className="p-2 text-gray-400 hover:text-primary-500 rounded-lg hover:bg-dark-50"
          >
            <Edit className="w-5 h-5" />
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div>
            <p className="text-gray-400">Power Output</p>
            <p className="text-xl font-semibold text-white">
              {vehicle.power} {vehicle.powerUnit}
            </p>
          </div>
          <div>
            <p className="text-gray-400">Weight</p>
            <p className="text-xl font-semibold text-white">
              {vehicle.weight} {vehicle.weightUnit}
            </p>
          </div>
          <div>
            <p className="text-gray-400">Power-to-Weight</p>
            <p className="text-xl font-semibold text-white">
              {calculatePowerToWeight()} lb/hp
            </p>
          </div>
          <div>
            <p className="text-gray-400">Total Invested</p>
            <p className="text-xl font-semibold text-white">
              ${totalSpent.toLocaleString()}
            </p>
          </div>
        </div>
      </div>

      {/* Vehicle Setup Section */}
      {editedVehicle.setup && (
        <VehicleSetupComponent
          setup={editedVehicle.setup}
          onUpdateSetup={handleUpdateSetup}
          weightUnit={editedVehicle.weightUnit}
        />
      )}

      {/* Modifications Section */}
      <div className="bg-dark-200 rounded-lg p-6">
        <h2 className="text-xl font-semibold text-white mb-6">Modifications</h2>

        {/* Add Modification Form */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
          <select
            value={newModification.type}
            onChange={(e) => setNewModification(prev => ({ ...prev, type: e.target.value as ModificationType }))}
            className="bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
          >
            {modificationTypes.map(type => (
              <option key={type} value={type}>{type}</option>
            ))}
          </select>
          <input
            type="text"
            value={newModification.name}
            onChange={(e) => setNewModification(prev => ({ ...prev, name: e.target.value }))}
            placeholder="Modification Name"
            className="bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
          />
          <input
            type="number"
            value={newModification.cost || ''}
            onChange={(e) => setNewModification(prev => ({ ...prev, cost: Number(e.target.value) }))}
            placeholder="Cost"
            className="bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
          />
          <button
            onClick={handleAddModification}
            className="flex items-center justify-center gap-2 bg-primary-500 text-white rounded-md px-4 py-2 hover:bg-primary-600"
          >
            <Plus className="w-4 h-4" />
            Add Modification
          </button>
        </div>

        {/* Modifications Table */}
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="text-left border-b border-dark-50">
                <th className="pb-3 text-gray-400 font-medium">Type</th>
                <th className="pb-3 text-gray-400 font-medium">Name</th>
                <th className="pb-3 text-gray-400 font-medium">Cost</th>
                <th className="pb-3 text-gray-400 font-medium">Actions</th>
              </tr>
            </thead>
            <tbody>
              {vehicle.modifications?.map((mod) => (
                <tr key={mod.id} className="border-b border-dark-50">
                  <td className="py-3 text-white">{mod.type}</td>
                  <td className="py-3 text-white">{mod.name}</td>
                  <td className="py-3 text-white">${mod.cost?.toLocaleString()}</td>
                  <td className="py-3">
                    <button
                      onClick={() => handleDeleteModification(mod.id)}
                      className="p-1 text-gray-400 hover:text-red-500"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </td>
                </tr>
              ))}
              {(!vehicle.modifications || vehicle.modifications.length === 0) && (
                <tr>
                  <td colSpan={4} className="py-4 text-center text-gray-400">
                    No modifications added yet.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}