import React from 'react';
import { TrendingUp, TrendingDown } from 'lucide-react';

interface BudgetSummaryPanelProps {
  totals: {
    totalExpenses: number;
    totalIncome: number;
    netBalance: number;
  };
}

export function BudgetSummaryPanel({ totals }: BudgetSummaryPanelProps) {
  return (
    <div className="bg-dark-200 rounded-lg p-6 space-y-6">
      <h2 className="text-lg font-semibold text-white">Budget Summary</h2>
      
      <div className="space-y-4">
        <div>
          <p className="text-sm text-gray-400">Total Expenses</p>
          <div className="flex items-center gap-2">
            <TrendingUp className="w-4 h-4 text-primary-500" />
            <p className="text-xl font-semibold text-white">
              ${totals.totalExpenses.toLocaleString()}
            </p>
          </div>
        </div>

        <div>
          <p className="text-sm text-gray-400">Total Income</p>
          <div className="flex items-center gap-2">
            <TrendingDown className="w-4 h-4 text-green-500" />
            <p className="text-xl font-semibold text-white">
              ${totals.totalIncome.toLocaleString()}
            </p>
          </div>
        </div>

        <div className="pt-4 border-t border-dark-50">
          <p className="text-sm text-gray-400">Net Balance</p>
          <p className={`text-2xl font-bold ${
            totals.netBalance >= 0 ? 'text-green-500' : 'text-red-500'
          }`}>
            ${Math.abs(totals.netBalance).toLocaleString()}
          </p>
        </div>
      </div>

      <div className="text-sm text-gray-400">
        <p className="mb-2">Quick Stats:</p>
        <ul className="space-y-1">
          <li>• Average Monthly Expenses: ${(totals.totalExpenses / 12).toLocaleString()}</li>
          <li>• Average Monthly Income: ${(totals.totalIncome / 12).toLocaleString()}</li>
          <li>• Expense to Income Ratio: {((totals.totalExpenses / totals.totalIncome) * 100).toFixed(1)}%</li>
        </ul>
      </div>
    </div>
  );
}