import React from 'react';
import { Plus, Edit, Trash2 } from 'lucide-react';
import type { Budget, BudgetItem, ExpenseCategory, IncomeCategory, FrequencyType } from '../../types/budget';

interface SeasonBudgetProps {
  budget: Budget;
  onUpdate: (budget: Budget) => void;
  type: 'expenses' | 'income';
}

const expenseCategories: ExpenseCategory[] = [
  'Entry Fees',
  'Travel',
  'Fuel',
  'Tires',
  'Parts',
  'Maintenance',
  'Equipment',
  'Safety Gear',
  'Marketing',
  'Insurance',
  'Other'
];

const incomeCategories: IncomeCategory[] = [
  'Sponsorship',
  'Prize Money',
  'Merchandise',
  'Appearance Fees',
  'Other'
];

const frequencies: FrequencyType[] = [
  'one-time',
  'per-event',
  'monthly',
  'quarterly',
  'yearly',
  'per-season'
];

export function SeasonBudget({ budget, onUpdate, type }: SeasonBudgetProps) {
  const [isAdding, setIsAdding] = React.useState(false);
  const [editingId, setEditingId] = React.useState<string | null>(null);
  const [newItem, setNewItem] = React.useState<Partial<BudgetItem>>({
    description: '',
    amount: 0,
    category: type === 'expenses' ? 'Other' : 'Other',
    quantity: 1,
    frequency: 'one-time'
  });

  const items = type === 'expenses' ? budget.seasonExpenses : budget.seasonIncome;
  const categories = type === 'expenses' ? expenseCategories : incomeCategories;

  const handleAddItem = () => {
    if (!newItem.description || !newItem.amount) return;

    const item: BudgetItem = {
      id: Math.random().toString(36).substr(2, 9),
      description: newItem.description,
      amount: Number(newItem.amount),
      category: newItem.category as (ExpenseCategory | IncomeCategory),
      quantity: Number(newItem.quantity) || 1,
      frequency: newItem.frequency as FrequencyType,
      notes: newItem.notes,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };

    const updatedBudget = {
      ...budget,
      [type === 'expenses' ? 'seasonExpenses' : 'seasonIncome']: [...items, item]
    };

    onUpdate(updatedBudget);
    setIsAdding(false);
    setNewItem({
      description: '',
      amount: 0,
      category: type === 'expenses' ? 'Other' : 'Other',
      quantity: 1,
      frequency: 'one-time'
    });
  };

  const handleUpdateItem = (id: string, updates: Partial<BudgetItem>) => {
    const updatedBudget = {
      ...budget,
      [type === 'expenses' ? 'seasonExpenses' : 'seasonIncome']: items.map(item =>
        item.id === id
          ? { ...item, ...updates, updatedAt: new Date().toISOString() }
          : item
      )
    };

    onUpdate(updatedBudget);
  };

  const handleDeleteItem = (id: string) => {
    if (!window.confirm('Are you sure you want to delete this item?')) return;

    const updatedBudget = {
      ...budget,
      [type === 'expenses' ? 'seasonExpenses' : 'seasonIncome']: items.filter(
        item => item.id !== id
      )
    };

    onUpdate(updatedBudget);
  };

  return (
    <div className="p-4">
      {/* Add Item Form */}
      {isAdding && (
        <div className="mb-4 bg-dark-100 rounded-lg p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <input
              type="text"
              value={newItem.description}
              onChange={(e) => setNewItem(prev => ({ ...prev, description: e.target.value }))}
              placeholder="Description"
              className="bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
            />
            <input
              type="number"
              value={newItem.amount}
              onChange={(e) => setNewItem(prev => ({ ...prev, amount: Number(e.target.value) }))}
              placeholder="Amount"
              min="0"
              step="0.01"
              className="bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
            />
            <select
              value={newItem.category}
              onChange={(e) => setNewItem(prev => ({ ...prev, category: e.target.value }))}
              className="bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
            >
              {categories.map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
            <div className="grid grid-cols-2 gap-2">
              <input
                type="number"
                value={newItem.quantity}
                onChange={(e) => setNewItem(prev => ({ ...prev, quantity: Number(e.target.value) }))}
                placeholder="Quantity"
                min="1"
                className="bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
              />
              <select
                value={newItem.frequency}
                onChange={(e) => setNewItem(prev => ({ ...prev, frequency: e.target.value as FrequencyType }))}
                className="bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
              >
                {frequencies.map(freq => (
                  <option key={freq} value={freq}>{freq.replace('-', ' ')}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex justify-end gap-2">
            <button
              onClick={() => setIsAdding(false)}
              className="px-4 py-2 text-sm text-gray-400 hover:text-gray-300"
            >
              Cancel
            </button>
            <button
              onClick={handleAddItem}
              disabled={!newItem.description || !newItem.amount}
              className="px-4 py-2 text-sm bg-primary-500 text-white rounded-md hover:bg-primary-600 disabled:opacity-50"
            >
              Add {type === 'expenses' ? 'Expense' : 'Income'}
            </button>
          </div>
        </div>
      )}

      {/* Add Button */}
      {!isAdding && (
        <button
          onClick={() => setIsAdding(true)}
          className="mb-4 flex items-center gap-2 px-4 py-2 text-sm text-primary-500 hover:text-primary-400"
        >
          <Plus className="w-4 h-4" />
          Add {type === 'expenses' ? 'Expense' : 'Income'}
        </button>
      )}

      {/* Items List */}
      <div className="space-y-2">
        {items.map((item) => (
          <div
            key={item.id}
            className="bg-dark-100 rounded-lg p-4 flex items-center justify-between"
          >
            {editingId === item.id ? (
              <div className="flex-1 grid grid-cols-1 md:grid-cols-2 gap-4">
                <input
                  type="text"
                  value={item.description}
                  onChange={(e) => handleUpdateItem(item.id, { description: e.target.value })}
                  className="bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                />
                <input
                  type="number"
                  value={item.amount}
                  onChange={(e) => handleUpdateItem(item.id, { amount: Number(e.target.value) })}
                  className="bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                />
                <select
                  value={item.category}
                  onChange={(e) => handleUpdateItem(item.id, { category: e.target.value as any })}
                  className="bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                >
                  {categories.map(category => (
                    <option key={category} value={category}>{category}</option>
                  ))}
                </select>
                <div className="grid grid-cols-2 gap-2">
                  <input
                    type="number"
                    value={item.quantity}
                    onChange={(e) => handleUpdateItem(item.id, { quantity: Number(e.target.value) })}
                    min="1"
                    className="bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                  />
                  <select
                    value={item.frequency}
                    onChange={(e) => handleUpdateItem(item.id, { frequency: e.target.value as FrequencyType })}
                    className="bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                  >
                    {frequencies.map(freq => (
                      <option key={freq} value={freq}>{freq.replace('-', ' ')}</option>
                    ))}
                  </select>
                </div>
              </div>
            ) : (
              <div className="flex-1">
                <div className="flex items-center gap-4">
                  <span className="text-white">{item.description}</span>
                  <span className="text-sm text-gray-400">
                    {item.quantity > 1 ? `${item.quantity}x ` : ''}
                    ${item.amount.toLocaleString()}
                  </span>
                  <span className="text-sm text-gray-400">{item.frequency?.replace('-', ' ')}</span>
                </div>
                <div className="text-sm text-gray-400">{item.category}</div>
              </div>
            )}
            <div className="flex gap-2">
              <button
                onClick={() => setEditingId(editingId === item.id ? null : item.id)}
                className="p-1 text-gray-400 hover:text-primary-500"
              >
                <Edit className="w-4 h-4" />
              </button>
              <button
                onClick={() => handleDeleteItem(item.id)}
                className="p-1 text-gray-400 hover:text-red-500"
              >
                <Trash2 className="w-4 h-4" />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}