import React from 'react';
import { ClipboardList } from 'lucide-react';
import type { Checklist } from '../../types/checklist';

interface EventChecklistProps {
  checklistId?: string;
  isEditing: boolean;
  onChange: (checklistId: string | undefined) => void;
}

// Mock checklists for development
const mockChecklists: Checklist[] = [
  {
    id: '1',
    name: 'Track Day Preparation',
    category: 'Event Prep',
    description: 'Essential checks and preparations before track day',
    items: [],
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  },
  {
    id: '2',
    name: 'Race Weekend Checklist',
    category: 'Event Prep',
    description: 'Complete checklist for race weekend preparation',
    items: [],
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  }
];

export default function EventChecklist({ 
  checklistId, 
  isEditing, 
  onChange 
}: EventChecklistProps) {
  const selectedChecklist = mockChecklists.find(c => c.id === checklistId);

  return (
    <div className="bg-dark-200 rounded-lg p-6">
      <div className="flex items-center gap-2 mb-6">
        <ClipboardList className="w-5 h-5 text-primary-500" />
        <h2 className="text-lg font-semibold text-white">Checklist</h2>
      </div>

      {isEditing ? (
        <div>
          <select
            value={checklistId || ''}
            onChange={(e) => onChange(e.target.value || undefined)}
            className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
          >
            <option value="">Select a checklist</option>
            {mockChecklists.map(checklist => (
              <option key={checklist.id} value={checklist.id}>
                {checklist.name}
              </option>
            ))}
          </select>
        </div>
      ) : selectedChecklist ? (
        <div>
          <h3 className="text-white font-medium mb-2">
            {selectedChecklist.name}
          </h3>
          <p className="text-gray-400 text-sm">
            {selectedChecklist.description}
          </p>
          <button className="mt-4 text-primary-500 hover:text-primary-400 text-sm">
            View Checklist →
          </button>
        </div>
      ) : (
        <p className="text-gray-400">No checklist selected</p>
      )}
    </div>
  );
}