import React from 'react';
import { Wrench } from 'lucide-react';
import { TaskDifficulty } from '../../types/task';

interface DifficultyIndicatorProps {
  difficulty: TaskDifficulty;
  editable?: boolean;
  onChange?: (difficulty: TaskDifficulty) => void;
}

export default function DifficultyIndicator({ 
  difficulty,
  editable = false,
  onChange
}: DifficultyIndicatorProps) {
  return (
    <div className="flex items-center gap-2">
      <span className="text-xs text-gray-400">Difficulty</span>
      <div className="flex gap-1">
        {Array.from({ length: 5 }, (_, i) => i + 1).map((value) => (
          <button
            key={value}
            onClick={() => editable && onChange?.(value as TaskDifficulty)}
            disabled={!editable}
            className={`p-1 rounded-full transition-colors ${
              editable ? 'hover:bg-dark-50' : ''
            }`}
          >
            <Wrench
              className={`w-4 h-4 ${
                value <= difficulty
                  ? 'text-primary-500'
                  : 'text-gray-600'
              }`}
            />
          </button>
        ))}
      </div>
    </div>
  );
}