import React from 'react';
import { Plus, Edit, Trash2, ChevronDown, ChevronUp } from 'lucide-react';
import type { Budget, BudgetItem, ExpenseCategory } from '../../types/budget';
import { format } from 'date-fns';

interface EventBudgetsProps {
  budget: Budget;
  onUpdate: (budget: Budget) => void;
}

const expenseCategories: ExpenseCategory[] = [
  'Entry Fees',
  'Travel',
  'Fuel',
  'Tires',
  'Parts',
  'Maintenance',
  'Equipment',
  'Safety Gear',
  'Marketing',
  'Insurance',
  'Other'
];

export function EventBudgets({ budget, onUpdate }: EventBudgetsProps) {
  const [expandedEvents, setExpandedEvents] = React.useState<string[]>([]);
  const [addingExpenseToEvent, setAddingExpenseToEvent] = React.useState<string | null>(null);
  const [editingExpense, setEditingExpense] = React.useState<string | null>(null);
  const [newExpense, setNewExpense] = React.useState<Partial<BudgetItem>>({
    description: '',
    amount: 0,
    category: 'Other',
    quantity: 1
  });

  const toggleEvent = (eventId: string) => {
    setExpandedEvents(prev =>
      prev.includes(eventId)
        ? prev.filter(id => id !== eventId)
        : [...prev, eventId]
    );
  };

  const handleAddExpense = (eventId: string) => {
    if (!newExpense.description || !newExpense.amount) return;

    const expense: BudgetItem = {
      id: Math.random().toString(36).substr(2, 9),
      description: newExpense.description,
      amount: Number(newExpense.amount),
      category: newExpense.category as ExpenseCategory,
      quantity: Number(newExpense.quantity) || 1,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };

    const updatedEvents = budget.events.map(event =>
      event.id === eventId
        ? {
            ...event,
            budget: {
              ...event.budget,
              expenses: [...event.budget.expenses, expense]
            },
            updatedAt: new Date().toISOString()
          }
        : event
    );

    onUpdate({
      ...budget,
      events: updatedEvents,
      updatedAt: new Date().toISOString()
    });

    setAddingExpenseToEvent(null);
    setNewExpense({
      description: '',
      amount: 0,
      category: 'Other',
      quantity: 1
    });
  };

  const handleUpdateExpense = (eventId: string, expenseId: string, updates: Partial<BudgetItem>) => {
    const updatedEvents = budget.events.map(event =>
      event.id === eventId
        ? {
            ...event,
            budget: {
              ...event.budget,
              expenses: event.budget.expenses.map(expense =>
                expense.id === expenseId
                  ? { ...expense, ...updates, updatedAt: new Date().toISOString() }
                  : expense
              )
            },
            updatedAt: new Date().toISOString()
          }
        : event
    );

    onUpdate({
      ...budget,
      events: updatedEvents,
      updatedAt: new Date().toISOString()
    });
  };

  const handleDeleteExpense = (eventId: string, expenseId: string) => {
    if (!window.confirm('Are you sure you want to delete this expense?')) return;

    const updatedEvents = budget.events.map(event =>
      event.id === eventId
        ? {
            ...event,
            budget: {
              ...event.budget,
              expenses: event.budget.expenses.filter(expense => expense.id !== expenseId)
            },
            updatedAt: new Date().toISOString()
          }
        : event
    );

    onUpdate({
      ...budget,
      events: updatedEvents,
      updatedAt: new Date().toISOString()
    });
  };

  return (
    <div className="space-y-4 p-4">
      {budget.events.length === 0 ? (
        <div className="text-center py-12">
          <p className="text-gray-400">No events scheduled yet.</p>
          <p className="text-sm text-gray-500 mt-2">Add events in the Schedule section to track their budgets.</p>
        </div>
      ) : (
        budget.events.map((event) => (
          <div key={event.id} className="bg-dark-100 rounded-lg overflow-hidden">
            <button
              onClick={() => toggleEvent(event.id)}
              className="w-full flex items-center justify-between p-4 hover:bg-dark-50"
            >
              <div>
                <span className="text-white font-medium">{event.title}</span>
                <span className="text-sm text-gray-400 ml-2">
                  {format(new Date(event.date), 'MMM d, yyyy')}
                </span>
              </div>
              {expandedEvents.includes(event.id) ? (
                <ChevronUp className="w-5 h-5 text-gray-400" />
              ) : (
                <ChevronDown className="w-5 h-5 text-gray-400" />
              )}
            </button>

            {expandedEvents.includes(event.id) && (
              <div className="p-4 border-t border-dark-50">
                {/* Add Expense Form */}
                {addingExpenseToEvent === event.id && (
                  <div className="bg-dark-50 rounded-lg p-4 mb-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-400 mb-1">
                          Description
                        </label>
                        <input
                          type="text"
                          value={newExpense.description}
                          onChange={(e) => setNewExpense(prev => ({ ...prev, description: e.target.value }))}
                          placeholder="Description"
                          className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-400 mb-1">
                          Category
                        </label>
                        <select
                          value={newExpense.category}
                          onChange={(e) => setNewExpense(prev => ({ ...prev, category: e.target.value as ExpenseCategory }))}
                          className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                        >
                          {expenseCategories.map(category => (
                            <option key={category} value={category}>{category}</option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-400 mb-1">
                          Amount ($)
                        </label>
                        <input
                          type="number"
                          value={newExpense.amount}
                          onChange={(e) => setNewExpense(prev => ({ ...prev, amount: Number(e.target.value) }))}
                          placeholder="0.00"
                          min="0"
                          step="0.01"
                          className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-400 mb-1">
                          Quantity
                        </label>
                        <input
                          type="number"
                          value={newExpense.quantity}
                          onChange={(e) => setNewExpense(prev => ({ ...prev, quantity: Number(e.target.value) }))}
                          placeholder="1"
                          min="1"
                          className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                        />
                      </div>
                    </div>
                    <div className="flex justify-end gap-2">
                      <button
                        onClick={() => setAddingExpenseToEvent(null)}
                        className="px-4 py-2 text-sm text-gray-400 hover:text-gray-300"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => handleAddExpense(event.id)}
                        disabled={!newExpense.description || !newExpense.amount}
                        className="px-4 py-2 text-sm bg-primary-500 text-white rounded-md hover:bg-primary-600 disabled:opacity-50"
                      >
                        Add Expense
                      </button>
                    </div>
                  </div>
                )}

                {/* Add Button */}
                {addingExpenseToEvent !== event.id && (
                  <button
                    onClick={() => setAddingExpenseToEvent(event.id)}
                    className="mb-4 flex items-center gap-2 px-4 py-2 text-sm text-primary-500 hover:text-primary-400"
                  >
                    <Plus className="w-4 h-4" />
                    Add Expense
                  </button>
                )}

                {/* Expenses List */}
                <div className="space-y-2">
                  {event.budget.expenses.length === 0 ? (
                    <p className="text-gray-400 text-center py-4">No expenses added yet.</p>
                  ) : (
                    event.budget.expenses.map((expense) => (
                      <div
                        key={expense.id}
                        className="bg-dark-50 rounded-lg p-4 flex items-center justify-between"
                      >
                        {editingExpense === expense.id ? (
                          <div className="flex-1 grid grid-cols-1 md:grid-cols-2 gap-4">
                            <input
                              type="text"
                              value={expense.description}
                              onChange={(e) =>
                                handleUpdateExpense(event.id, expense.id, {
                                  description: e.target.value,
                                })
                              }
                              className="bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                            />
                            <select
                              value={expense.category}
                              onChange={(e) =>
                                handleUpdateExpense(event.id, expense.id, {
                                  category: e.target.value as ExpenseCategory,
                                })
                              }
                              className="bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                            >
                              {expenseCategories.map((category) => (
                                <option key={category} value={category}>
                                  {category}
                                </option>
                              ))}
                            </select>
                            <input
                              type="number"
                              value={expense.amount}
                              onChange={(e) =>
                                handleUpdateExpense(event.id, expense.id, {
                                  amount: Number(e.target.value),
                                })
                              }
                              className="bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                            />
                            <input
                              type="number"
                              value={expense.quantity}
                              onChange={(e) =>
                                handleUpdateExpense(event.id, expense.id, {
                                  quantity: Number(e.target.value),
                                })
                              }
                              min="1"
                              className="bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                            />
                          </div>
                        ) : (
                          <div className="flex-1">
                            <div className="flex items-center gap-4">
                              <span className="text-white">{expense.description}</span>
                              <span className="text-sm text-gray-400">
                                {expense.quantity > 1 ? `${expense.quantity}x ` : ''}
                                ${expense.amount.toLocaleString()}
                              </span>
                            </div>
                            <div className="text-sm text-gray-400">{expense.category}</div>
                          </div>
                        )}
                        <div className="flex gap-2">
                          <button
                            onClick={() =>
                              setEditingExpense(editingExpense === expense.id ? null : expense.id)
                            }
                            className="p-1 text-gray-400 hover:text-primary-500"
                          >
                            <Edit className="w-4 h-4" />
                          </button>
                          <button
                            onClick={() => handleDeleteExpense(event.id, expense.id)}
                            className="p-1 text-gray-400 hover:text-red-500"
                          >
                            <Trash2 className="w-4 h-4" />
                          </button>
                        </div>
                      </div>
                    ))
                  )}
                </div>

                {/* Event Total */}
                <div className="mt-4 pt-4 border-t border-dark-50 flex justify-between text-sm">
                  <span className="text-gray-400">Event Total</span>
                  <span className="text-white font-medium">
                    ${event.budget.expenses
                      .reduce((sum, expense) => sum + expense.amount * expense.quantity, 0)
                      .toLocaleString()}
                  </span>
                </div>
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
}