import React from 'react';
import { format, isFuture, isPast, compareDesc } from 'date-fns';
import { Calendar } from 'lucide-react';
import type { Event } from '../../types/event';

interface EventsOverviewProps {
  events: Event[];
  onSelectEvent: (event: Event) => void;
}

export function EventsOverview({ events, onSelectEvent }: EventsOverviewProps) {
  // Get upcoming events (sorted by nearest)
  const upcomingEvents = events
    .filter(event => isFuture(new Date(event.date)))
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .slice(0, 2);

  // Get recent past events (sorted by most recent)
  const recentEvents = events
    .filter(event => isPast(new Date(event.date)))
    .sort((a, b) => compareDesc(new Date(a.date), new Date(b.date)))
    .slice(0, 2);

  // Get last edited events
  const lastEditedEvents = [...events]
    .sort((a, b) => compareDesc(new Date(a.updatedAt), new Date(b.updatedAt)))
    .slice(0, 2);

  const EventCard = ({ event }: { event: Event }) => (
    <button
      onClick={() => onSelectEvent(event)}
      className="w-full bg-dark-100 rounded-lg p-4 hover:bg-dark-50 transition-colors text-left"
    >
      <div className="flex items-start gap-3">
        <div className="p-2 bg-primary-500/10 rounded-lg">
          <Calendar className="h-5 w-5 text-primary-500" />
        </div>
        <div>
          <h4 className="text-white font-medium">{event.title}</h4>
          <p className="text-sm text-gray-400">
            {format(new Date(event.date), 'MMM d, yyyy')}
          </p>
          {event.venue && (
            <p className="text-sm text-gray-400 mt-1">{event.venue}</p>
          )}
        </div>
      </div>
    </button>
  );

  const Section = ({ title, events }: { title: string; events: Event[] }) => (
    <div className="space-y-3">
      <h3 className="text-lg font-medium text-white">{title}</h3>
      {events.length > 0 ? (
        <div className="space-y-2">
          {events.map(event => (
            <EventCard key={event.id} event={event} />
          ))}
        </div>
      ) : (
        <p className="text-gray-400">No events to show</p>
      )}
    </div>
  );

  return (
    <div className="bg-dark-200 rounded-lg p-6 space-y-6">
      <Section title="Upcoming Events" events={upcomingEvents} />
      <Section title="Recent Events" events={recentEvents} />
      <Section title="Last Edited" events={lastEditedEvents} />
    </div>
  );
}