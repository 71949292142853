import React from 'react';
import {
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  format,
  isSameMonth,
  isSameDay,
} from 'date-fns';
import type { Event } from '../../types/event';

interface MonthViewProps {
  currentDate: Date;
  events: Event[];
  onSelectDate: (date: Date) => void;
  onSelectEvent: (event: Event) => void;
}

export function MonthView({ currentDate, events, onSelectDate, onSelectEvent }: MonthViewProps) {
  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(monthStart);
  const calendarStart = startOfWeek(monthStart);
  const calendarEnd = endOfWeek(monthEnd);

  const days = eachDayOfInterval({ start: calendarStart, end: calendarEnd });

  const getEventColor = (type: Event['type']) => {
    switch (type) {
      case 'competition':
        return 'bg-red-500/20 text-red-400';
      case 'practice':
        return 'bg-blue-500/20 text-blue-400';
      case 'maintenance':
        return 'bg-yellow-500/20 text-yellow-400';
      case 'education':
        return 'bg-green-500/20 text-green-400';
      case 'exhibition':
        return 'bg-purple-500/20 text-purple-400';
      case 'testing':
        return 'bg-orange-500/20 text-orange-400';
      case 'media':
        return 'bg-pink-500/20 text-pink-400';
      case 'development':
        return 'bg-indigo-500/20 text-indigo-400';
      case 'sim':
        return 'bg-cyan-500/20 text-cyan-400';
      default:
        return 'bg-gray-500/20 text-gray-400';
    }
  };

  return (
    <div className="bg-dark-200 rounded-lg overflow-hidden">
      <div className="grid grid-cols-7 text-sm font-medium text-gray-400 border-b border-dark-50">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
          <div key={day} className="p-4 text-center">
            {day}
          </div>
        ))}
      </div>
      <div className="grid grid-cols-7">
        {days.map((day, dayIdx) => {
          const dayEvents = events.filter((event) => isSameDay(new Date(event.date), day));
          
          return (
            <div
              key={day.toISOString()}
              onClick={() => onSelectDate(day)}
              className={`min-h-[120px] p-2 border-b border-r border-dark-50 cursor-pointer transition-colors
                ${!isSameMonth(day, monthStart) ? 'bg-dark-300' : 'hover:bg-dark-100'}`}
            >
              <div className="flex justify-between items-start">
                <span
                  className={`text-sm font-medium ${
                    isSameMonth(day, monthStart) ? 'text-gray-300' : 'text-gray-600'
                  }`}
                >
                  {format(day, 'd')}
                </span>
              </div>
              <div className="mt-2 space-y-1">
                {dayEvents.map((event) => (
                  <button
                    key={event.id}
                    onClick={(e) => {
                      e.stopPropagation();
                      onSelectEvent(event);
                    }}
                    className={`w-full px-2 py-1 text-xs rounded-md truncate text-left transition-colors hover:brightness-125 ${
                      getEventColor(event.type)
                    }`}
                  >
                    {event.title}
                  </button>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}