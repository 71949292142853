import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Save, X } from 'lucide-react';
import type { Task, TaskStatus, TaskPriority, TaskDifficulty } from '../types/task';
import PriorityIndicator from '../components/tasks/PriorityIndicator';
import DifficultyIndicator from '../components/tasks/DifficultyIndicator';
import { format } from 'date-fns';

const statusOptions: { value: TaskStatus; label: string }[] = [
  { value: 'someday', label: 'Someday' },
  { value: 'ready', label: 'Ready' },
  { value: 'on-track', label: 'On Track' },
  { value: 'pit-stop', label: 'Pit Stop' },
  { value: 'done', label: 'Done' }
];

const defaultTask: Omit<Task, 'id'> = {
  title: '',
  description: '',
  status: 'someday',
  priority: 4,
  difficulty: 3,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  labels: []
};

interface TaskDetailProps {
  tasks: Task[];
  onUpdateTask: (task: Task) => void;
}

export default function TaskDetail({ tasks, onUpdateTask }: TaskDetailProps) {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const isNew = id === 'new';
  const [isEditing, setIsEditing] = React.useState(isNew);
  const [task, setTask] = React.useState<Task | undefined>(
    isNew 
      ? { ...defaultTask, id: crypto.randomUUID() }
      : tasks.find(t => t.id === id)
  );
  const [editedTask, setEditedTask] = React.useState<Task | undefined>(task);
  const [newLabel, setNewLabel] = React.useState('');

  React.useEffect(() => {
    if (!isNew && !task) {
      navigate('/tasks');
    }
  }, [task, navigate, isNew]);

  if (!task || !editedTask) {
    return null;
  }

  const handleSave = () => {
    if (editedTask) {
      onUpdateTask(editedTask);
      setTask(editedTask);
      setIsEditing(false);
      navigate('/tasks');
    }
  };

  const handleCancel = () => {
    if (isNew) {
      navigate('/tasks');
    } else {
      setEditedTask(task);
      setIsEditing(false);
    }
  };

  const handleAddLabel = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && newLabel.trim()) {
      setEditedTask(prev => ({
        ...prev!,
        labels: [...(prev?.labels || []), newLabel.trim()]
      }));
      setNewLabel('');
    }
  };

  const handleRemoveLabel = (labelToRemove: string) => {
    setEditedTask(prev => ({
      ...prev!,
      labels: prev?.labels?.filter(label => label !== labelToRemove) || []
    }));
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <button
            onClick={() => navigate('/tasks')}
            className="p-2 text-gray-400 hover:text-primary-500 rounded-lg hover:bg-dark-50"
          >
            <ArrowLeft className="w-5 h-5" />
          </button>
          <h1 className="text-2xl font-bold text-white">
            {isNew ? 'New Task' : editedTask.title}
          </h1>
        </div>
        <div className="flex gap-2">
          {!isNew && !isEditing && (
            <button
              onClick={() => setIsEditing(true)}
              className="px-4 py-2 bg-dark-100 text-white rounded-md hover:bg-dark-50"
            >
              Edit
            </button>
          )}
          {isEditing && (
            <>
              <button
                onClick={handleSave}
                className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600"
              >
                <Save className="w-4 h-4" />
                {isNew ? 'Create Task' : 'Save Changes'}
              </button>
              <button
                onClick={handleCancel}
                className="flex items-center gap-2 px-4 py-2 bg-dark-100 text-gray-400 rounded-md hover:bg-dark-50"
              >
                <X className="w-4 h-4" />
                Cancel
              </button>
            </>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Main Content */}
        <div className="lg:col-span-2 space-y-6">
          {/* Task Details */}
          <div className="bg-dark-200 rounded-lg p-6">
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-400 mb-1">
                  Title
                </label>
                <input
                  type="text"
                  value={editedTask.title}
                  onChange={e => setEditedTask(prev => ({ ...prev!, title: e.target.value }))}
                  disabled={!isEditing}
                  className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
                  placeholder="Task title"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-400 mb-1">
                  Description
                </label>
                <textarea
                  value={editedTask.description}
                  onChange={e => setEditedTask(prev => ({ ...prev!, description: e.target.value }))}
                  disabled={!isEditing}
                  rows={4}
                  className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
                  placeholder="Task description"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-400 mb-1">
                  Status
                </label>
                <select
                  value={editedTask.status}
                  onChange={e => setEditedTask(prev => ({ ...prev!, status: e.target.value as TaskStatus }))}
                  disabled={!isEditing}
                  className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
                >
                  {statusOptions.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-400 mb-1">
                  Due Date
                </label>
                <input
                  type="date"
                  value={editedTask.dueDate}
                  onChange={e => setEditedTask(prev => ({ ...prev!, dueDate: e.target.value }))}
                  disabled={!isEditing}
                  className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-400 mb-1">
                  Priority
                </label>
                <PriorityIndicator priority={editedTask.priority} />
                {isEditing && (
                  <input
                    type="range"
                    min="1"
                    max="8"
                    value={editedTask.priority}
                    onChange={e => setEditedTask(prev => ({ ...prev!, priority: Number(e.target.value) as TaskPriority }))}
                    className="w-full mt-2"
                  />
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-400 mb-1">
                  Difficulty
                </label>
                <DifficultyIndicator
                  difficulty={editedTask.difficulty}
                  editable={isEditing}
                  onChange={difficulty => setEditedTask(prev => ({ ...prev!, difficulty }))}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-400 mb-1">
                  Labels
                </label>
                <div className="flex flex-wrap gap-2 mb-2">
                  {editedTask.labels?.map(label => (
                    <div
                      key={label}
                      className="flex items-center gap-1 px-2 py-1 rounded-full bg-dark-100 text-sm text-gray-400"
                    >
                      {label}
                      {isEditing && (
                        <button
                          onClick={() => handleRemoveLabel(label)}
                          className="ml-1 text-gray-500 hover:text-gray-400"
                        >
                          ×
                        </button>
                      )}
                    </div>
                  ))}
                </div>
                {isEditing && (
                  <input
                    type="text"
                    value={newLabel}
                    onChange={e => setNewLabel(e.target.value)}
                    onKeyDown={handleAddLabel}
                    className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
                    placeholder="Add label (press Enter)"
                  />
                )}
              </div>
            </div>
          </div>

          {/* Notes Section */}
          <div className="bg-dark-200 rounded-lg p-6">
            <h2 className="text-lg font-semibold text-white mb-4">Notes</h2>
            <textarea
              value={editedTask.notes}
              onChange={e => setEditedTask(prev => ({ ...prev!, notes: e.target.value }))}
              disabled={!isEditing}
              rows={6}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 disabled:opacity-50"
              placeholder="Add notes about this task..."
            />
          </div>
        </div>

        {/* Sidebar */}
        <div className="space-y-6">
          {/* Metadata */}
          <div className="bg-dark-200 rounded-lg p-6">
            <h2 className="text-lg font-semibold text-white mb-4">Details</h2>
            <div className="space-y-4 text-sm">
              <div>
                <p className="text-gray-400">Created</p>
                <p className="text-white">
                  {format(new Date(task.createdAt), 'MMM d, yyyy h:mm a')}
                </p>
              </div>
              {!isNew && (
                <div>
                  <p className="text-gray-400">Last Updated</p>
                  <p className="text-white">
                    {format(new Date(task.updatedAt), 'MMM d, yyyy h:mm a')}
                  </p>
                </div>
              )}
              {task.checklistId && (
                <div>
                  <p className="text-gray-400">Linked Checklist</p>
                  <p className="text-white">View Checklist →</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}