import React from 'react';
import { Settings } from 'lucide-react';
import type { EventSetup } from '../../types/event';

interface EventSetupProps {
  setupId?: string;
  isEditing: boolean;
  onChange: (setupId: string | undefined) => void;
}

// Mock setup for development
const mockSetup: EventSetup = {
  id: '1',
  name: 'Default Setup',
  notes: 'Base setup for dry conditions'
};

export default function EventSetup({ 
  setupId, 
  isEditing, 
  onChange 
}: EventSetupProps) {
  return (
    <div className="bg-dark-200 rounded-lg p-6">
      <div className="flex items-center gap-2 mb-6">
        <Settings className="w-5 h-5 text-primary-500" />
        <h2 className="text-lg font-semibold text-white">Vehicle Setup</h2>
      </div>

      {isEditing ? (
        <div>
          <select
            value={setupId || ''}
            onChange={(e) => onChange(e.target.value || undefined)}
            className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
          >
            <option value="">Select a setup</option>
            <option value={mockSetup.id}>{mockSetup.name}</option>
          </select>
        </div>
      ) : setupId ? (
        <div>
          <h3 className="text-white font-medium mb-2">{mockSetup.name}</h3>
          {mockSetup.notes && (
            <p className="text-gray-400 text-sm">{mockSetup.notes}</p>
          )}
          <button className="mt-4 text-primary-500 hover:text-primary-400 text-sm">
            View Setup Details →
          </button>
        </div>
      ) : (
        <p className="text-gray-400">No setup selected</p>
      )}
    </div>
  );
}