import React from 'react';
import { Link } from 'react-router-dom';
import { Calendar, MapPin } from 'lucide-react';
import { format, isFuture } from 'date-fns';
import type { Event } from '../../types/event';

interface UpcomingEventsProps {
  events: Event[];
}

export default function UpcomingEvents({ events }: UpcomingEventsProps) {
  const upcomingEvents = events
    .filter(event => isFuture(new Date(event.date)))
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .slice(0, 3);

  return (
    <div className="bg-dark-200 rounded-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-lg font-semibold text-white">Upcoming Events</h2>
        <Link 
          to="/schedule"
          className="text-sm text-primary-500 hover:text-primary-400"
        >
          View All
        </Link>
      </div>

      <div className="space-y-4">
        {upcomingEvents.length === 0 ? (
          <p className="text-gray-400 text-center py-4">No upcoming events</p>
        ) : (
          upcomingEvents.map(event => (
            <Link
              key={event.id}
              to={`/schedule?date=${event.date}`}
              className="block bg-dark-100 rounded-lg p-4 hover:bg-dark-50 transition-colors"
            >
              <div className="flex items-start gap-4">
                <div className="flex-shrink-0 w-12 h-12 bg-dark-300 rounded-lg flex items-center justify-center">
                  <Calendar className="w-6 h-6 text-primary-500" />
                </div>
                <div className="flex-1 min-w-0">
                  <h3 className="text-white font-medium truncate">{event.title}</h3>
                  <p className="text-sm text-gray-400">
                    {format(new Date(event.date), 'MMM d, yyyy')}
                  </p>
                  {event.venue && (
                    <div className="flex items-center gap-1 mt-1 text-sm text-gray-500">
                      <MapPin className="w-3 h-3" />
                      <span>{event.venue}</span>
                    </div>
                  )}
                </div>
                <div className={`px-2 py-1 text-xs rounded-full ${
                  event.type === 'race'
                    ? 'bg-red-500/20 text-red-400'
                    : event.type === 'practice'
                    ? 'bg-blue-500/20 text-blue-400'
                    : event.type === 'maintenance'
                    ? 'bg-yellow-500/20 text-yellow-400'
                    : 'bg-gray-500/20 text-gray-400'
                }`}>
                  {event.type}
                </div>
              </div>
            </Link>
          ))
        )}
      </div>
    </div>
  );
}