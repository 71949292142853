import React, { useState } from 'react';
import { Plus, Search, Filter } from 'lucide-react';
import InventoryCard from './InventoryCard';
import AddPartModal from './AddPartModal';
import type { Vehicle } from '../../types/vehicle';
import type { Part, InventoryGroup } from '../../types/part';
import { DndContext, DragEndEvent, DragStartEvent, useSensor, useSensors, PointerSensor, DragOverlay } from '@dnd-kit/core';

// Example inventory items
const initialInventory: Part[] = [
  {
    id: '1',
    type: 'Engine',
    name: 'K24A2 Engine',
    description: 'Complete engine assembly, low mileage (65k), includes harness and ECU',
    manufacturer: 'Honda',
    partNumber: 'K24A2-R205',
    cost: 3500,
    quantity: 1,
    location: 'Main Storage Unit B3',
    condition: 'used',
    notes: 'Pulled from 2007 TSX, compression tested',
    minimumStock: 1,
    budgetCategory: 'Parts',
    createdAt: '2024-01-15T10:00:00Z',
    updatedAt: '2024-01-15T10:00:00Z'
  },
  {
    id: '2',
    type: 'Wheels/Tires',
    name: 'Hoosier A7 205/50R15',
    description: 'Autocross/Track compound',
    manufacturer: 'Hoosier',
    partNumber: 'HOO46730',
    cost: 285,
    quantity: 4,
    location: 'Tire Rack A1',
    condition: 'new',
    minimumStock: 4,
    budgetCategory: 'Tires',
    expirationDate: '2025-12-31T00:00:00Z',
    createdAt: '2024-02-01T10:00:00Z',
    updatedAt: '2024-02-01T10:00:00Z'
  },
  {
    id: '3',
    type: 'Brakes',
    name: 'Hawk DTC-60 Brake Pads',
    description: 'High-temperature race compound',
    manufacturer: 'Hawk',
    partNumber: 'HB453U.585',
    cost: 189,
    quantity: 2,
    location: 'Parts Shelf C2',
    condition: 'new',
    minimumStock: 2,
    budgetCategory: 'Parts',
    createdAt: '2024-02-15T10:00:00Z',
    updatedAt: '2024-02-15T10:00:00Z'
  },
  {
    id: '4',
    type: 'Engine',
    name: 'Mobil 1 0W-40 Oil',
    description: 'Full synthetic motor oil',
    manufacturer: 'Mobil',
    partNumber: 'MOB120760',
    cost: 65,
    quantity: 8,
    location: 'Fluids Cabinet',
    condition: 'new',
    minimumStock: 6,
    budgetCategory: 'Parts',
    expirationDate: '2026-03-01T00:00:00Z',
    createdAt: '2024-03-01T10:00:00Z',
    updatedAt: '2024-03-01T10:00:00Z'
  },
  {
    id: '5',
    type: 'Suspension',
    name: 'Ohlins DFV Coilover Kit',
    description: 'Road & Track series, includes springs',
    manufacturer: 'Ohlins',
    partNumber: 'OHL-RT440',
    cost: 2450,
    quantity: 1,
    location: 'Main Storage Unit A1',
    condition: 'new',
    minimumStock: 1,
    budgetCategory: 'Parts',
    createdAt: '2024-02-20T10:00:00Z',
    updatedAt: '2024-02-20T10:00:00Z'
  },
  {
    id: '6',
    type: 'Wheels/Tires',
    name: 'Enkei RPF1 15x8 +35',
    description: '4x100 bolt pattern, black finish',
    manufacturer: 'Enkei',
    partNumber: 'RPF1-1580',
    cost: 259,
    quantity: 4,
    location: 'Wheel Storage',
    condition: 'new',
    minimumStock: 4,
    budgetCategory: 'Parts',
    createdAt: '2024-01-20T10:00:00Z',
    updatedAt: '2024-01-20T10:00:00Z'
  },
  {
    id: '7',
    type: 'Brakes',
    name: 'Motul RBF 660 Brake Fluid',
    description: 'High-temperature racing brake fluid',
    manufacturer: 'Motul',
    partNumber: 'MOT-RBF660',
    cost: 35,
    quantity: 3,
    location: 'Fluids Cabinet',
    condition: 'new',
    minimumStock: 2,
    budgetCategory: 'Parts',
    expirationDate: '2025-06-01T00:00:00Z',
    createdAt: '2024-03-05T10:00:00Z',
    updatedAt: '2024-03-05T10:00:00Z'
  },
  {
    id: '8',
    type: 'Engine',
    name: 'K&N Air Filter',
    description: 'High-flow replacement filter',
    manufacturer: 'K&N',
    partNumber: 'KN33-2304',
    cost: 75,
    quantity: 2,
    location: 'Parts Shelf B1',
    condition: 'new',
    minimumStock: 1,
    budgetCategory: 'Parts',
    createdAt: '2024-02-10T10:00:00Z',
    updatedAt: '2024-02-10T10:00:00Z'
  },
  {
    id: '9',
    type: 'Transmission',
    name: 'ACT Street-Lite Flywheel',
    description: 'Chromoly steel flywheel',
    manufacturer: 'ACT',
    partNumber: 'ACT600180',
    cost: 395,
    quantity: 1,
    location: 'Parts Shelf D3',
    condition: 'new',
    minimumStock: 1,
    budgetCategory: 'Parts',
    createdAt: '2024-01-25T10:00:00Z',
    updatedAt: '2024-01-25T10:00:00Z'
  },
  {
    id: '10',
    type: 'Exterior',
    name: 'APR Carbon Fiber Wing',
    description: 'GTC-200 Adjustable Wing, 61"',
    manufacturer: 'APR',
    partNumber: 'APR-GTC200',
    cost: 895,
    quantity: 1,
    location: 'Main Storage Unit C1',
    condition: 'new',
    minimumStock: 1,
    budgetCategory: 'Parts',
    createdAt: '2024-02-28T10:00:00Z',
    updatedAt: '2024-02-28T10:00:00Z'
  },
  {
    id: '11',
    type: 'Interior',
    name: 'Sparco EVO QRT Racing Seat',
    description: 'FIA approved, black, large size',
    manufacturer: 'Sparco',
    partNumber: 'SPR-EVO-QRT',
    cost: 1199,
    quantity: 2,
    location: 'Main Storage Unit A2',
    condition: 'new',
    minimumStock: 2,
    budgetCategory: 'Safety Gear',
    createdAt: '2024-03-10T10:00:00Z',
    updatedAt: '2024-03-10T10:00:00Z'
  },
  {
    id: '12',
    type: 'Wheels/Tires',
    name: 'Bridgestone RE-71RS 245/40R17',
    description: 'Extreme performance summer tire',
    manufacturer: 'Bridgestone',
    partNumber: 'BR-RE71RS',
    cost: 225,
    quantity: 2,
    location: 'Tire Rack B2',
    condition: 'new',
    minimumStock: 4,
    budgetCategory: 'Tires',
    expirationDate: '2026-01-01T00:00:00Z',
    createdAt: '2024-03-15T10:00:00Z',
    updatedAt: '2024-03-15T10:00:00Z'
  }
];

interface InventorySectionProps {
  vehicles: Vehicle[];
  setVehicles: React.Dispatch<React.SetStateAction<Vehicle[]>>;
}

export default function InventorySection({ vehicles, setVehicles }: InventorySectionProps) {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedType, setSelectedType] = useState<string>('all');
  const [inventory, setInventory] = useState<Part[]>(initialInventory);

  React.useEffect(() => {
    console.log('InventorySection component mounted');
  }, []);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const [activePart, setActivePart] = useState<Part | null>(null);

  const handleDragStart = (event: DragStartEvent) => {
    console.log('Inventory drag started:', event.active.id);
    const part = inventory.find(p => p.id === event.active.id);
    if (part) {
      console.log('Active inventory part:', part);
      setActivePart(part);
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    console.log('Inventory drag ended:', event.active.id, 'over:', event.over?.id);
    const { active, over } = event;

    if (!over) return;

    const part = inventory.find(p => p.id === active.id);
    const vehicle = vehicles.find(v => v.id === over.id);

    if (part && vehicle) {
      console.log('Installing inventory part:', part, 'to vehicle:', vehicle);
      handleInstallPart(part.id, vehicle.id);
    }

    setActivePart(null);
  };

  const handleAddPart = (part: Omit<Part, 'id' | 'createdAt' | 'updatedAt'>) => {
    console.log('Adding new part:', part);
    const newPart: Part = {
      ...part,
      id: crypto.randomUUID(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };
    setInventory(prev => [...prev, newPart]);
    setIsAddModalOpen(false);
  };

  const handleInstallPart = (partId: string, vehicleId: string) => {
    const part = inventory.find(p => p.id === partId);
    if (!part) return;

    console.log('Removing part from inventory:', part);

    // Remove from inventory
    setInventory(prev => prev.filter(p => p.id !== partId));

    // Add to vehicle modifications
    setVehicles(prev => prev.map(vehicle => {
      if (vehicle.id === vehicleId) {
        console.log('Adding part to vehicle:', vehicle);
        return {
          ...vehicle,
          modifications: [
            ...(vehicle.modifications || []),
            {
              ...part,
              date: new Date().toISOString()
            }
          ]
        };
      }
      return vehicle;
    }));
  };

  // Group parts by type
  const groupedInventory = inventory.reduce((groups, part) => {
    const group = groups.find(g => g.type === part.type);
    if (group) {
      group.parts.push(part);
    } else {
      groups.push({ type: part.type, parts: [part] });
    }
    return groups;
  }, [] as InventoryGroup[]);

  // Filter inventory based on search and type
  const filteredGroups = groupedInventory
    .map(group => ({
      ...group,
      parts: group.parts.filter(part => 
        part.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
        (selectedType === 'all' || part.type === selectedType)
      )
    }))
    .filter(group => group.parts.length > 0);

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold text-white">Inventory</h2>
          <button
            onClick={() => {
              console.log('Add Part button clicked');
              setIsAddModalOpen(true);
            }}
            className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600"
          >
            <Plus className="w-5 h-5" />
            Add Part
          </button>
        </div>

        {/* Search and Filter */}
        <div className="flex gap-4">
          <div className="flex-1 relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => {
                console.log('Search query changed:', e.target.value);
                setSearchQuery(e.target.value);
              }}
              placeholder="Search inventory..."
              className="w-full bg-dark-200 border border-dark-50 rounded-md pl-10 pr-4 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500"
            />
          </div>
          <div className="relative">
            <Filter className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <select
              value={selectedType}
              onChange={(e) => {
                console.log('Filter type changed:', e.target.value);
                setSelectedType(e.target.value);
              }}
              className="bg-dark-200 border border-dark-50 rounded-md pl-10 pr-4 py-2 text-white focus:outline-none focus:ring-2 focus:ring-primary-500 appearance-none"
            >
              <option value="all">All Types</option>
              <option value="Engine">Engine</option>
              <option value="Suspension">Suspension</option>
              <option value="Brakes">Brakes</option>
              <option value="Transmission">Transmission</option>
              <option value="Exterior">Exterior</option>
              <option value="Interior">Interior</option>
              <option value="Wheels/Tires">Wheels/Tires</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>

        {/* Inventory Grid */}
        <div className="space-y-6">
          {filteredGroups.map(group => (
            <div key={group.type} className="space-y-4">
              <h3 className="text-lg font-semibold text-white">{group.type}</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {group.parts.map(part => (
                  <InventoryCard
                    key={part.id}
                    part={part}
                    vehicles={vehicles}
                    onInstall={handleInstallPart}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* Add Part Modal */}
        <AddPartModal
          isOpen={isAddModalOpen}
          onClose={() => setIsAddModalOpen(false)}
          onAdd={handleAddPart}
          vehicles={vehicles}
        />
      </div>

      <DragOverlay>
        {activePart ? <InventoryCard part={activePart} vehicles={vehicles} onInstall={handleInstallPart} /> : null}
      </DragOverlay>
    </DndContext>
  );
}