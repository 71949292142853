import React from 'react';
import { Link } from 'react-router-dom';
import { CheckSquare } from 'lucide-react';
import type { Task } from '../../types/task';

interface TasksSummaryProps {
  tasks: Task[];
}

export default function TasksSummary({ tasks }: TasksSummaryProps) {
  const highPriorityTasks = tasks
    .filter(task => task.priority >= 6 && task.status !== 'done')
    .sort((a, b) => b.priority - a.priority)
    .slice(0, 3);

  const tasksByStatus = tasks.reduce((acc, task) => {
    acc[task.status] = (acc[task.status] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  return (
    <div className="bg-dark-200 rounded-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-lg font-semibold text-white">Tasks Overview</h2>
        <Link 
          to="/tasks"
          className="text-sm text-primary-500 hover:text-primary-400"
        >
          View All
        </Link>
      </div>

      <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mb-6">
        <div className="text-center p-3 bg-dark-100 rounded-lg">
          <p className="text-2xl font-semibold text-white">{tasksByStatus['someday'] || 0}</p>
          <p className="text-sm text-gray-400">Someday</p>
        </div>
        <div className="text-center p-3 bg-dark-100 rounded-lg">
          <p className="text-2xl font-semibold text-white">{tasksByStatus['on-track'] || 0}</p>
          <p className="text-sm text-gray-400">On Track</p>
        </div>
        <div className="text-center p-3 bg-dark-100 rounded-lg">
          <p className="text-2xl font-semibold text-white">{tasksByStatus['pit-stop'] || 0}</p>
          <p className="text-sm text-gray-400">Pit Stop</p>
        </div>
        <div className="text-center p-3 bg-dark-100 rounded-lg">
          <p className="text-2xl font-semibold text-white">{tasksByStatus['done'] || 0}</p>
          <p className="text-sm text-gray-400">Completed</p>
        </div>
      </div>

      <div>
        <h3 className="text-sm font-medium text-gray-400 mb-4">High Priority Tasks</h3>
        <div className="space-y-3">
          {highPriorityTasks.length === 0 ? (
            <p className="text-gray-500 text-center py-2">No high priority tasks</p>
          ) : (
            highPriorityTasks.map(task => (
              <Link
                key={task.id}
                to={`/tasks/${task.id}`}
                className="flex items-center gap-3 p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors"
              >
                <CheckSquare className="w-5 h-5 text-primary-500" />
                <div className="flex-1 min-w-0">
                  <h4 className="text-sm font-medium text-white truncate">{task.title}</h4>
                  <p className="text-xs text-gray-400">Priority Level: {task.priority}</p>
                </div>
                <span className={`px-2 py-1 text-xs rounded-full ${
                  task.status === 'pit-stop'
                    ? 'bg-red-500/20 text-red-400'
                    : task.status === 'on-track'
                    ? 'bg-blue-500/20 text-blue-400'
                    : 'bg-gray-500/20 text-gray-400'
                }`}>
                  {task.status}
                </span>
              </Link>
            ))
          )}
        </div>
      </div>
    </div>
  );
}