import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Plus } from 'lucide-react';
import VehicleCard from '../components/vehicles/VehicleCard';
import InventorySection from '../components/garage/InventorySection';
import type { Vehicle } from '../types/vehicle';
import { DndContext, DragEndEvent, DragStartEvent, useSensor, useSensors, PointerSensor } from '@dnd-kit/core';

// Example vehicles
const initialVehicles: Vehicle[] = [
  {
    id: '1',
    make: 'Mazda',
    model: 'MX-5 Miata',
    year: 2019,
    type: 'Convertible',
    weight: 2339,
    weightUnit: 'lbs',
    power: 181,
    powerUnit: 'HP',
    drivetrain: 'RWD',
    primaryUse: 'Autocross',
    nickname: 'Track Day Toy',
    notes: 'Perfect for weekend track days and autocross events.',
    modifications: [
      {
        id: 'mod1',
        type: 'Suspension',
        name: 'Ohlins Coilovers',
        description: 'Road & Track spec',
        cost: 2500,
        date: '2023-08-15'
      },
      {
        id: 'mod2',
        type: 'Engine',
        name: 'K&N Air Filter',
        description: 'High-flow air filter',
        cost: 75,
        date: '2023-07-01'
      }
    ]
  },
  {
    id: '2',
    make: 'Toyota',
    model: 'GR86',
    year: 2022,
    type: 'Coupe',
    weight: 2811,
    weightUnit: 'lbs',
    power: 228,
    powerUnit: 'HP',
    drivetrain: 'RWD',
    primaryUse: 'Time Attack',
    vin: 'JF1ZNAA12N8750142',
    modifications: [
      {
        id: 'mod3',
        type: 'Brakes',
        name: 'Brembo GT Kit',
        description: '4-piston front calipers',
        cost: 3200,
        date: '2023-09-20'
      }
    ]
  }
];

interface GarageProps {
  vehicles: Vehicle[];
  setVehicles: React.Dispatch<React.SetStateAction<Vehicle[]>>;
}

export default function Garage({ vehicles, setVehicles }: GarageProps) {
  const [activePart, setActivePart] = useState(null);

  React.useEffect(() => {
    console.log('Garage component mounted');
    if (!vehicles || vehicles.length === 0) {
      console.log('Initializing vehicles');
      setVehicles(initialVehicles);
    }
  }, [vehicles, setVehicles]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const handleDragStart = (event: DragStartEvent) => {
    console.log('Drag started:', event.active.id);
    const part = vehicles.flatMap(v => v.modifications).find(mod => mod.id === event.active.id);
    if (part) {
      console.log('Active part:', part);
      setActivePart(part);
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    console.log('Drag ended:', event.active.id, 'over:', event.over?.id);
    const { active, over } = event;

    if (!over) return;

    const part = vehicles.flatMap(v => v.modifications).find(mod => mod.id === active.id);
    const vehicle = vehicles.find(v => v.id === over.id);

    if (part && vehicle) {
      console.log('Installing part:', part, 'to vehicle:', vehicle);
      // Implement logic to move part from one vehicle to another or from inventory to vehicle
    }

    setActivePart(null);
  };

  const handleDeleteVehicle = (id: string) => {
    if (window.confirm('Are you sure you want to delete this vehicle?')) {
      console.log('Deleting vehicle with id:', id);
      setVehicles(prev => prev.filter(vehicle => vehicle.id !== id));
    }
  };

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <div className="space-y-8">
        {/* Vehicles Section */}
        <div className="space-y-6">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold text-white">Vehicles</h2>
            <Link
              to="/garage/vehicles/new"
              className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600"
            >
              <Plus className="w-5 h-5" />
              Add Vehicle
            </Link>
          </div>

          {!vehicles || vehicles.length === 0 ? (
            <div className="text-center py-12">
              <p className="text-gray-400">No vehicles added yet.</p>
              <Link
                to="/garage/vehicles/new"
                className="mt-4 text-primary-500 hover:text-primary-400"
              >
                Add your first vehicle
              </Link>
            </div>
          ) : (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              {vehicles.map(vehicle => (
                <VehicleCard
                  key={vehicle.id}
                  vehicle={vehicle}
                  onEdit={() => {}} // We'll handle this through navigation now
                  onDelete={handleDeleteVehicle}
                />
              ))}
            </div>
          )}
        </div>

        {/* Inventory Section */}
        <InventorySection vehicles={vehicles} setVehicles={setVehicles} />
      </div>
    </DndContext>
  );
}